<template>
  <el-row :gutter="20" class="pro-container pro-base-msg pro-spec">
    <!-- <el-button @click="showdata"></el-button> -->
    <el-row>
      <el-col :span="2">
        <!-- 规格设置 -->
        <h6 class="left-h6">{{ $t('productDetail.SpecificationSetting') }}</h6>
      </el-col>
      <el-col :span="22" class="right-content">
        <el-row>
          <label class="mr10">
            {{ $t('productDetail.EnableMultipleSpecifications') }}
          </label>
          <el-switch v-model="isSpecs" :disabled="pageType != 'clone' && isAuditHistory"
            @change="specsChange"></el-switch>
        </el-row>
        <el-row type="flex" class="align_center mt30" v-if="isSpecs">
          <el-form ref="form" :model="form" :rules="rules" inline>
            <!-- 请选择规格 -->
            <el-form-item prop="specName" :label="$t('productDetail.SelectASpecification')" style="margin-bottom: 0">
              <el-select v-model="form.specName" :placeholder="$t('placeholder.plsSel')"
                :disabled="pageType != 'clone' && isAuditHistory" @change="specSelChange">
                <el-option v-for="(item, index) of specData" :key="item + '_' + index" :label="item.nameCn"
                  :value="item" :disabled="item.disabled" />
              </el-select>
              <!-- Tip：请先选择产品类别，且规格最多配置两个 -->
              <span class="f_s_12 ml15">
                Tip：{{ $t('productDetail.Tips') }}
              </span>
            </el-form-item>
          </el-form>
        </el-row>
        <!-- 规格名称值展示 -->
        <el-table :data="tableData" stripe border class="mt30 w100 list-table" v-if="isSpecs" :key="tableKey">
          <!-- 排序 -->
          <el-table-column align="center" type="index" width="55"
            :label="$t('productDetail.SerialNumber')"></el-table-column>
          <!-- 规格名称 -->
          <el-table-column align="center" :label="$t('productDetail.SpecificationName')" width="150" prop="nameCn" />
          <!-- 规格值(可拖动排序) -->
          <el-table-column align="center" :label="$t('productDetail.SpecificationValue')">
            <template slot-scope="scope" inline-template>
              <el-row align="middle" class="pr20 draggable-row">
                <erp-draggable v-bind="dragOptions" :list="scope.row.value" @end="endClick" class="mb10 ml-10">
                  <template v-for="tag in scope.row.value">
                    <template v-if="
                        pageType == 'edit' &&
                        getStatusFromSpeclist(tag, scope.$index)
                      ">
                      <el-tag class="c_pointer ml10" :disable-transitions="false" v-if="
                          !(
                            downShelfSpecs[scope.$index] &&
                            downShelfSpecs[scope.$index].includes(tag)
                          )
                        ">
                        {{ tag }}
                        <el-tooltip class="item" effect="light" :content="$t('productDetail.DownSpecTip')"
                          placement="top-start">
                          <i class="iconfont icon-xiajia" @click="
                              handleDownOrUp(
                                'down',
                                tag,
                                scope.$index,
                                '',
                                scope.row
                              )
                            "></i>
                        </el-tooltip>
                      </el-tag>
                    </template>
                    <template v-else>
                      <el-tag class="c_pointer ml10" closable :disable-transitions="false"
                        @close="handleClose(scope.row, tag, scope.$index)">
                        {{ tag }}
                      </el-tag>
                    </template>
                  </template>
                </erp-draggable>

                <!-- 编辑的时候加入状态显示 -->
                <template v-if="
                    pageType == 'edit' &&
                    downShelfSpecs.length &&
                    downShelfSpecs[scope.$index] &&
                    downShelfSpecs[scope.$index].length
                  ">
                  <el-row type="flex" align="middle" class="down-tag ml10">
                    <span>
                      {{ $t('productDetail.SpecDown') }}
                    </span>
                    <template v-for="(downSpec, index) in downShelfSpecs[scope.$index]">
                      <el-tag class="c_pointer ml10" :disable-transitions="false" type="info" style="margin-top: 0">
                        {{ downSpec }}
                        <i class="iconfont icon-shangjia" @click="
                            handleDownOrUp(
                              'up',
                              downSpec,
                              scope.$index,
                              index,
                              scope.row
                            )
                          "></i>
                      </el-tag>
                    </template>
                  </el-row>
                </template>

                <el-row type="flex">
                  <!-- 请选择 -->
                  <el-select :placeholder="$t('placeholder.plsSel')" filterable v-model="specSelected[scope.$index]"
                    multiple @change="
                      (arg) => {
                        multipleChooseSpec(
                          arg,
                          scope.row.specOption,
                          scope.$index
                        )
                      }
                    " @visible-change="
                      multipleVisibleChange($event, scope.$index)
                    ">
                    <el-option v-for="(item, index) in scope.row.specOption" :key="item + '_' + index"
                      :label="item.value" :value="item.value" :disabled="item.disabled"></el-option>
                  </el-select>

                  <el-row type="flex" align="middle" style="flex: 1" class="saveTagInput-row">
                    <el-input v-if="currentEditing && currentEditing === scope.row" ref="saveTagInput"
                      v-model ="inputValue" v-focus class="input-new-tag w_100 ml10" @input="inputValueblur"
                      @blur="handleInputConfirm(scope.row, scope.$index)" v-filterSpace/>
                    <!-- 添加规格值 -->
                    <el-button class="button-new-tag ml10" @click="showInput(scope.row)" v-if="productTypeValue !== 2">
                      {{ $t('other.addSpec') }}
                    </el-button>
                    <!-- 一键清空 -->
                    <span v-if="isAllowClear(scope.row, scope.$index).length" class="f_s_12 ml10 c_pointer" style="
                        color: #1890ff;
                        white-space: nowrap;
                        margin-top: 3px;
                      " @click="handleClear(scope.row, scope.$index)">
                      {{ $t('other.oneClear') }}
                    </span>
                  </el-row>
                </el-row>
              </el-row>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column align="center" :label="$t('productDetail.Operate')" show-overflow-tooltip width="150"
            fixed="right">
            <template slot-scope="scope">
              <el-button type="danger" plain icon="el-icon-delete" circle size="mini"
                :disabled="pageType != 'clone' && isAuditHistory"
                @click="handleDel(scope.row, scope.$index)"></el-button>
              <el-button v-if="tableData.length > 1" type="primary" plain circle size="mini"
                :icon="scope.$index === 0 ? 'el-icon-bottom' : 'el-icon-top'" @click="swapIndex"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-row class="mt10 f_s_12">Tip：规格值可拖动排序</el-row> -->
        <el-row class="mt20" v-if="tableData && tableData.length > 0 && isSpecs">
          <!-- 规格列表 -->
          <p>{{ $t('productDetail.SpecificationList') }}</p>
          <el-table border class="mt10" :data="specList" :key="tableKey" :row-class-name="rowClassName">
            <!-- 产品编码 -->
            <el-table-column :label="$t('productDetail.ProductCode')" prop="sku" align="center" width="120"
              v-if="pageType === 'edit'"></el-table-column>
            <!-- 规格图 -->
            <el-table-column :label="$t('productDetail.SpecificationDrawing')" width="200" prop="image" align="center">
              <template slot-scope="scope">
                <div class="wrapper-box" @click="
                    choseRendingImages(
                      scope.$index,
                      specList[scope.$index].image,
                      1
                    )
                  ">
                  <div class="wrapper-image" v-show="specList[scope.$index].image">
                    <img :src="getFirstImgUrl(specList[scope.$index].image)" width="50px" height="50px" alt />
                  </div>
                  <div class="wrapper-add" v-show="!specList[scope.$index].image">
                    <el-row>
                      <i class="el-icon-plus"></i>
                    </el-row>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="(item, index) in tableData" :key="tableKey + index" :label="item.nameCn" width="200"
              align="center">
              <template slot-scope="scope">
                <span>{{ scope.row[`specsValue${index + 1}`] }}</span>
              </template>
            </el-table-column>
            <!-- 状态 -->
            <el-table-column :label="$t('productDetail.Status')" prop="specsOptionStatus" align="center" width="120"
              v-if="pageType === 'edit'">
              <template slot-scope="scope">
                {{
                getShelfFromDownShelfSpecs(scope.row)
                ? $t('productDetail.Deactivated')
                : $t('productDetail.Normal')
                }}
              </template>
            </el-table-column>
            <!-- 备注信息 -->
            <el-table-column :label="$t('productDetail.Remarks')" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remarks" type="textarea" maxlength="500" show-word-limit
                  :autosize="{ minRows: 2, maxRows: 5 }" :placeholder="$t('placeholder.M2004')" @blur="setSpecSync" />
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <!-- 选择印刷效果图 -->
    <choose-images :visible.sync="chooseImagesDialogVisible" :default-file-list="currentDefaultRendingList"
      @confirm="setRendingImages" :limit="1"></choose-images>
  </el-row>
</template>

<script>
  import ErpDraggable from 'vuedraggable'
  import { listSpecByCategoryId } from '@/api/product/catesManager'
  import ChooseImages from './chooseImages'
  import { elFormErrorScrollIntoView, deepCopy } from 'kits'
  import _ from 'lodash'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ProductSpecSet',
    components: { ErpDraggable, ChooseImages },

    data() {
      return {
        mockSpecList: [],
        downShelfSpecs: [],
        isAuditHistory: false,
        categoryId: '', //产品分类id
        specData: [], //产品规格
        form: {
          specName: [], //选择的规格名称
        },
        specValue: [], //选择的规格值
        tableData: [], //规格table
        currentEditing: null, //规格input
        inputValue: '', //规格input的model
        specsType: '', //
        specsValue: '',
        stopWatch: false, //停止监听
        isSingleBol: true, //单规格false和多规格判断true,用来判断验证,默认单规格
        editData: [],
        specSelected: [[], []], // 规格选择值
        specList: [], //规格列表
        currentRendingIndex: 0, //
        chooseImagesDialogVisible: false, // 选择规格图片弹窗
        currentDefaultRendingList: [], // 当前选中的规格图片
        currentDefaultRendingListBackups: [], // 当前选中的规格图片，备份
        sortable: null,
        tableKey: '',
        isSpecs: true, // 开启多规格
        pageType: '',
        multipleVisibleChangeShow: false, // 多规格选择框是否显示
        multipleVisibleChangeShowIndex: 0, // 多规格选择框显示的索引
        rules: {
          specName: [
            // 请输入中文名称
            {
              required: true,
              message: this.$t('placeholder.plsSel'),
              trigger: 'change',
            },
          ],
        },
        iseditBol:false,//是否是编辑状态
      }
    },
    props: {
      productTypeValue: {
        type: Number,
        default: 0
      },
    },
    computed: {
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      ...mapGetters({
        lang: 'settings/language',
      })
    },

    created() {
      this.pageType = this.$route.query.str
      this.tableKey = Math.random()
      // 产品分类id
      this.$Bus.$on('categoryId', (id) => {
        this.categoryId = id
        if (this.categoryId) {
          this.getListSpecByCategoryId()
        } else {
          this.form.specName = ''
          this.specData = []
          this.tableData = []
          this.stopWatch = true
          this.$forceUpdate()
        }
      })
      this.getProSpecMsg()
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate('specName')
      })
    },

    methods: {
      // 获取产品信息中的信息
      getProSpecMsg() {
        this.$Bus.$on('specProMsg', (val) => {
          this.specList = val
        })
      },

      // 根据分类id获取产品关联的规格
      async getListSpecByCategoryId() {
        // 旧规格
        this.prevSpecDataNames = this.specData
          .map((spec) => spec?.nameCn)
          .join(',')
        let response = await listSpecByCategoryId({
          productCategoryId: this.categoryId,
        })
        if ((response.code === '000000')) {
          this.specData = response?.data || []
          const specDataNames = this.specData
            .map((spec) => spec?.nameCn)
            .join(',')

          if (
            this.prevSpecDataNames != specDataNames &&
            this.prevSpecDataNames
          ) {
            this.tableData = []
            this.form.specName = ''
            this.$forceUpdate()
            setTimeout(() => {
              this.$Bus.$emit('show-clear')
            }, 100)
          }
          this.specData.forEach((item) => {
            item.value = item.value?.split(',') ?? []
            item.valueCustomize = item.valueCustomize?.split(',') ?? []
            item.disabled = false
          })

          this.specData.forEach((it) => {
            it.specCustomizationOption = it.valueCustomize.map((ot) => {
              return {
                value: ot,
                disabled: false,
              }
            })
            it.specStandardsOption = it.value.map((ot) => {
              return {
                value: ot,
                disabled: false,
              }
            })
          })
          this.specData = this.specData.map(item=>{
            item.specOption = this.productTypeValue === 2 ? item.specStandardsOption : item.specCustomizationOption
            return item
          })
        }
      },
      
      async editShow(editData) {
        if (editData) {
          if(editData.standardCustomized && this.$route.query.str !== 'clone'){
            this.iseditBol = true
          }
          this.editData = editData
          this.isAuditHistory = editData.auditHistory == 1 ? true : false
          if (this.pageType == 'edit') {
            this.downShelfSpecs = editData.downShelfSpecs
              ? JSON.parse(editData.downShelfSpecs)
              : [[], []]
            let tempArr = [[], []]
            tempArr.forEach((item, index) => {
              if (!this.downShelfSpecs[index]) {
                this.$set(this.downShelfSpecs, index, [])
              }
            })
          }

          if (
            this.editData.productSpecsEditVOList &&
            this.editData.productSpecsEditVOList.length > 0 &&
            this.$route.query.str === 'clone'
          ) {
            this.editData.productSpecsEditVOList =
              this.editData.productSpecsEditVOList.map((it) => {
                if (this.pageType == 'clone') {
                  it.imageId = ''
                  it.shelf = 0
                  it.specsOptionStatus = 0
                }
                return {
                  ...it,
                  sku: '',
                  productSpecsId: '',
                  productId: '',
                }
              })
          }
          if (!this.stopWatch) {
            if (this.editData.categoryId) {
              //根据产品分类id获取规格下拉
              this.categoryId =
                this.editData.categoryId.split(',')[
                this.editData.categoryId.split(',').length - 1
                ]
              if (this.categoryId && this.categoryId.length) {
                await this.getListSpecByCategoryId()
              }
            }
            // 是否多规格
            if (this.editData.specsType === '') {
              this.isSpecs = false
            } else {
              this.isSpecs = true
            }
            this.$Bus.$emit('isSingleSpec', this.isSpecs, 'edit')
            this.specList = this.editData.productSpecsEditVOList

            this.mockSpecList = JSON.parse(JSON.stringify(this.specList))

            //规格转换
            // 规格回显 1.8.0
            await this.reBackSpecs()
          }
        }
      },

      reBackSpecs() {
        if (this.editData.specsType && this.tableData.length === 0) {
          let specsType = this.editData.specsType.split(',')
          let specsValue = JSON.parse(
            this.editData.specsValue.replace(/\'/g, '"')
          )

          let specList = []
          specsType.forEach((ot) => {
            this.specData.forEach((it) => {
              if (it?.nameCn === ot) {
                specList.push(it)
              }
            })
          })

          this.tableData = []
          specsType.forEach((item, index) => {
            let obj = {
              disabled: true,
              nameCn: item,
              value: specsValue[index] || [],
              specOption: specList[index]?.specOption,
              specStandardsOption:specList[index]?.specStandardsOption,
              specCustomizationOption:specList[index]?.specCustomizationOption,
            }
            this.tableData.push(obj)
          })
          this.tableData.forEach((item) => {
            this.specData.forEach((item2) => {
              if (item?.nameCn === item2?.nameCn) {
                this.$set(item2, 'disabled', true)
                item2.specOption.forEach((it) => {
                  if (item.value && item.value.indexOf(it.value) > -1) {
                    this.$set(it, 'disabled', true)
                  }
                })
              }
            })
          })

          setTimeout(() => {
            this.$Bus.$emit('specSendBusEdit', this.tableData)
          }, 100)
        }

        this.setSpecSync('edit')
      },

      getStatusFromSpeclist(tag, index) {
        let tempArr = this.specList?.filter(
          (item) => tag == item[index == 0 ? 'specsValue1' : 'specsValue2']
        )
        let obj = tempArr?.find(
          (ele) => ele.shelf === 0 && ele.specsOptionStatus === 1
        )
        return obj
      },

      //从下架规格列表中读取状态
      getShelfFromDownShelfSpecs(row) {
        let isDown = false
        for (let j = 0; j < this.downShelfSpecs.length; j++) {
          for (let k = 0; k < this.downShelfSpecs[j].length; k++) {
            if (
              row.specsValue1 == this.downShelfSpecs[j][k] ||
              row.specsValue2 == this.downShelfSpecs[j][k]
            ) {
              isDown = true
              return isDown
            }
          }
        }
      },

      //上下架操作
      handleDownOrUp(type, tag, $index, index, row) {
        if (type == 'down') {
          if (this.downShelfSpecs[$index]) {
            this.downShelfSpecs[$index].push(tag)
          } else {
            this.downShelfSpecs[$index].push([tag])
          }
        }
        if (type == 'up') {
          this.downShelfSpecs[$index].splice(index, 1)
        }
        if (row.nameCn === 'Color') {
          this.setSpecSync()
        }
      },

      showSpecName() {
        let tempArr = []
        this.tableData.forEach((item) => {
          tempArr.push(item.nameCn)
        })
        this.form.specName = tempArr.join(',')
      },

      // 产品规格选择
      specSelChange(val) {
        if(!this.productTypeValue){
           this.form.specName = ''
          this.$message.warning(this.$t('rules.M2004'))
          return
        }
        if (this.tableData.length >= 2) {
          this.form.specName = ''
          this.showSpecName()
          return this.$baseMessage(
            // '产品规格最多配置两个',
            this.$t('reqmsg.M2026'),
            'warning',
            false,
            'erp-hey-message-warning'
          )
        }
        this.specData.forEach((item, index) => {
          if (item?.nameCn === val?.nameCn) {
            item.disabled = true
          }
        })

        // 1.8.0 选择一项规格值后，不可再次选择
        this.$set(val, 'specOption', {})
        // val.specOption = val.value.map((ot) => {
        //   return {
        //     value: ot.value ? ot.value : ot,
        //     disabled: false,
        //   }
        // })
        val.specOption = this.productTypeValue === 2 ? val.specStandardsOption : val.specCustomizationOption
        this.$set(val, 'value', [])
        this.tableData.push(val)

        this.form.specName = ''

        this.setSpecList()
        this.setSpecSync()
      },

      // 规格值多选，不改变其他逻辑
      multipleChooseSpec(valArr, list, $index) {
        this.currentList = list
        this.currentIndex = $index
      },

     async multipleVisibleChange(show, idx) {
        this.multipleVisibleChangeShow = show
        this.multipleVisibleChangeShowIndex = idx
        if (!show) {
          this.specSelected[idx].forEach( async(item) => {
           await this.chooseSpec(item, this.currentList, this.currentIndex)
          })
          this.specSelected[idx] = []
          this.currentList = []
          this.currentIndex = ''
        }
      },
      // 规格值单选
     async setMultipleVisibleChange() {
        if(this.multipleVisibleChangeSho){
         await this.multipleVisibleChange(false, this.multipleVisibleChangeShowIndex)
        }
      },
      //1.8.0 选择规格值
    async chooseSpec(val, list, $index) {
        // 设置规格值不可重复选择
        let selectedSpec = list.find((it) => {
          return it.value === val
        })
        this.$set(selectedSpec, 'disabled', true)
        // 向value 中添加值
        this.tableData[$index].value.push(val)
        // this.setSpecList()
        // this.setSpecSync()
        // this.compareCurrSpecAndOldSpec(val, $index)
        await Promise.all([this.setSpecList(), this.setSpecSync(), this.compareCurrSpecAndOldSpec(val, $index)]);
      },

      //规格单个tag删除
      handleClose(row, tag) {
        //1.8.0 删除，规格可选择
        let deleted =
          row.specOption &&
          row.specOption.find((it) => {
            return it.value === tag
          })
        if (deleted) {
          this.$set(deleted, 'disabled', false)
        }

        row.value.splice(row.value.indexOf(tag), 1)
        this.setSpecList()
        this.setSpecSync()
      },

      showInput(row) {
        this.currentEditing = row
      },

      // 规格input确认
      handleInputConfirm(row, $index) {
        this.inputValue = this.inputValue.trim()
        if (this.inputValue.indexOf("'") > -1) {
          this.$baseMessage(
            // '请先选择规格',
            this.$t('productDetail.SpecialSymbolsAreNotAllowed'),
            'error',
            false,
            'erp-hey-message-error'
          )
          return
        }
          // 检查输入值是否已存在（不区分大小写）
          let valueObjArr = []
          for(let k of row.value){
            valueObjArr.push({
              value: k,
              toLowerCaseValue: k.toLowerCase()
            })
          }
          const repeatValue = valueObjArr?.find(item => item.toLowerCaseValue === this.inputValue.toLowerCase())

          if(repeatValue && this.inputValue){
            this.$message.warning(this.lang == 'zh'? `已存在${repeatValue.value}` : 'Duplicate value')
            this.inputValue = '' 
            return;
          }
          let specOptionValueArr = []
          if(row.specOption){
            for(let k of row.specOption){
              specOptionValueArr.push({
                value: k.value,
                toLowerCaseValue: k.value.toLowerCase()
              })
            }
          }
          const repeatspecOptionValue = specOptionValueArr?.find(item => item.toLowerCaseValue === this.inputValue.toLowerCase())
          if (repeatspecOptionValue && this.inputValue) {
            this.$message.warning(this.lang == 'zh'? `已存在${repeatspecOptionValue.value}` : 'Duplicate value')
            this.inputValue = '' 
            return;
          }
        // 输入的规格值在下拉中有，下拉列表中的不可选
        row.specOption &&
          row.specOption.forEach((it) => {
            if (it.value == this.inputValue) {
              this.$set(it, 'disabled', true)
            }
          })

        if (this.inputValue) {
          this.tableData[$index].value.push(this.inputValue)
          this.currentEditing = null
          this.setSpecList()
          this.setSpecSync()
          this.compareCurrSpecAndOldSpec(this.inputValue, $index)
          this.inputValue = ''
        } else {
          this.currentEditing = null
        }
      },

      //拖拽结束之后
      endClick() {
        this.setSpecList()
        // this.setSpecSync()
      },

      //清空按钮是否有
      isAllowClear(row, $index) {
        let tempArr = []
        if (row.value && row.value.length) {
          for (let i = row.value.length - 1; i >= 0; i--) {
            if (!this.getStatusFromSpeclist(row.value[i], $index)) {
              tempArr.push(row.value[i])
            }
          }
        }
        return tempArr
      },

      //一键清空
      handleClear(row, $index) {
        let tempArr = []
        for (let i = row.value.length - 1; i >= 0; i--) {
          if (!this.getStatusFromSpeclist(row.value[i], $index)) {
            tempArr.push(row.value[i])
            row.value.splice(i, 1)
          }
        }
        this.$message.success(this.$t('reqmsg.$7'))
        row.specOption &&
          row.specOption.forEach((it) => {
            if (tempArr.length) {
              tempArr.forEach((ele) => {
                if (ele == it.value) {
                  this.$set(it, 'disabled', false)
                }
              })
            } else {
              this.$set(it, 'disabled', false)
            }
          })

        this.getListSpecByCategoryId()
        this.setSpecList()
        this.setSpecSync()
      },

      // 规格整行删除
      handleDel(row, $index) {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          this.specData.forEach((item, index) => {
            if (item.nameCn === row.nameCn) {
              item.disabled = false
              item.value = item.specOption
            }
          })
          this.tableData.forEach((item, index) => {
            if (row.nameCn === item.nameCn) {
              this.tableData.splice(index, 1)
            }
          })
          this.$message.success(this.$t('reqmsg.$7'))
          this.setSpecList()
          this.setSpecSync()
        })
      },

      // 同步规格值
    async setSpecSync(str) {
       await this.showSpecName()
       this.$Bus.$emit(
            'specSendBus',
            this.tableData,
            this.specList,
            this.downShelfSpecs
          )
      },

      //比较新旧规格值，如果新的规格值组合和旧的规格值组合值一样，就用旧的值覆盖新的值
    async compareCurrSpecAndOldSpec(tag, $index) {
        let specList = this.specList.filter(
          (ele) => tag == ($index == 0 ? ele.specsValue1 : ele.specsValue2)
        )
        specList?.forEach(async(item) => {
        await  this.mockSpecList?.forEach(async(ele) => {
            if (
              item.specsValue1 == ele.specsValue1 &&
              item.specsValue2 == ele.specsValue2
            ) {
              for (const key in ele) {
                this.$set(item, key, ele[key])
              }
              this.$set(item, 'shelf', 0)
              this.$set(item, 'specsOptionStatus', 0)
            }
            if (
              item.specsValue1 == ele.specsValue2 &&
              item.specsValue2 == ele.specsValue1
            ) {
              for (const key in ele) {
                this.$set(item, key, ele[key])
              }
              this.$set(item, 'specsValue1', ele.specsValue2)
              this.$set(item, 'specsValue2', ele.specsValue1)
              this.$set(item, 'shelf', 0)
              this.$set(item, 'specsOptionStatus', 0)
            }
          })
        })
      },

      // 设置规格列表值
     async setSpecList(tag) {
        let specArr = []
        /*
        1.无规格值
        2.有一个规格值。specsValue1
        3.有两个规格值，specsValue1，specsValue2
      */
        if (this.tableData.length == 1) {
          this.tableData[0].value?.forEach((jt, j) => {
            let obj = {
              specsValue1: this.tableData[0].value[j],
              specsValue2: '',
            }
            specArr.push(obj)
          })
        }
        if (this.tableData.length == 2) {
          if (this.tableData[0].value.length == 0) {
            this.tableData[1].value?.forEach((jt, j) => {
              let obj = {
                image: '',
                specsValue1: '',
                specsValue2: jt,
              }
              specArr.push(obj)
            })
          }
          if (this.tableData[1].value.length == 0) {
            this.tableData[0].value?.forEach((it, i) => {
              let obj = {
                image: '',
                specsValue1: it,
                specsValue2: '',
              }
              specArr.push(obj)
            })
          }
          if (
            this.tableData[1].value.length !== 0 &&
            this.tableData[0].value.length !== 0
          ) {
            this.tableData[0].value?.forEach((it, i) => {
              this.tableData[1].value?.forEach((jt, j) => {
                let obj = {
                  image: '',
                  specsValue1: it,
                  specsValue2: jt,
                }
                specArr.push(obj)
              })
            })
          }
        }

        if (this.specList?.length > 0) {
          specArr.forEach((it, i) => {
            this.specList.forEach((ot, o) => {
              if (
                // tag === 'drag' &&
                it.specsValue2 === ot?.specsValue1 &&
                it.specsValue1 === ot?.specsValue2
              ) {
                specArr[i] = ot
                specArr[i].specsValue1 = it.specsValue1
                specArr[i].specsValue2 = it.specsValue2
              } else if (
                it.specsValue1 === ot?.specsValue1 &&
                it.specsValue2 === ot?.specsValue2
              ) {
                specArr[i] = ot
                specArr[i].specsValue1 = it.specsValue1
                specArr[i].specsValue2 = it.specsValue2
              }
            })
          })
        }
        this.tableKey = Math.random()
        this.specList = [...specArr]

        if (tag === 'drag') {
         await this.setSpecSync()
        }
      },

      // 上移下移
      swapIndex() {
        this.tableData.splice(
          0,
          1,
          ...this.tableData.splice(1, 1, this.tableData[0])
        )
        //下架规格值也需要同步移动
        if (this.downShelfSpecs.length) {
          this.downShelfSpecs.splice(
            0,
            1,
            ...this.downShelfSpecs.splice(1, 1, this.downShelfSpecs[0])
          )
        }
        this.setSpecList('drag')
      },

      // 开启规格
      specsChange() {
        this.setSpecList()
        this.$Bus.$emit('isSingleSpec', this.isSpecs)
        this.clearData()
      },

      //验证
     async validateField() {
        let success = false
        let specsType = []
        let specsValue = []
        let delName = []
        let validFlag = false
        this.setMultipleVisibleChange()
        if (this.isSpecs) {
          this.$refs.form.validate(async (valid) => {
            if (valid) {
              validFlag = true
            } else {
              success = false
              // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
              this.$nextTick(() => {
                elFormErrorScrollIntoView('.el-form-item__error')
              })
            }
          })
          if (validFlag) {
            this.tableData.forEach((item, index) => {
              specsType.push(item?.nameCn)
              if (item.value.length > 0) {
                specsValue.push(item.value)
              }
            })
            specsType = specsType.join(',')
            specsValue = JSON.stringify(specsValue).replace(/\"/g, "'")
            if (specsType) {
              this.tableData.forEach((item) => {
                if (item.value.length == 0) {
                  delName.push(item.nameCn)
                }
              })
              if (delName.length) {
                success = false
                // 使用$nextTick的原因是，错误提示的元素是在视图更新后出现的，不使用$nextTick获取元素是undefined
                this.$nextTick(() => {
                  elFormErrorScrollIntoView('.pro-spec')
                })
                let tip =
                  '规格设置中（' + delName.join(',') + '）对应的的规格值必填'
                this.$baseMessage(
                  tip,
                  'warning',
                  false,
                  'erp-hey-message-warning'
                )
              } else {
                success = true
              }
            }
            success = !await this.handleHasSpecValue() 
          }
        } else {
          success = true
          // 单规格
          specsType = ''
          specsValue = ''
        }

        if (success) {
          return {
            success: true,
            specsType,
            specsValue,
            specsSwitch: this.isSpecs ? 1 : 0,
            downShelfSpecs: JSON.stringify(this.downShelfSpecs),
          }
        } else {
          return {
            success: false,
          }
        }
      },

      //保存到本地
    async validateField2() {
        let specsType = []
        let specsValue = []
       await this.setMultipleVisibleChange()
        this.tableData.forEach((item, index) => {
          specsType.push(item?.nameCn)
          specsValue.push(item.value)
        })
        specsType = specsType.join(',')
        specsValue = JSON.stringify(specsValue).replace(/\"/g, "'")
        // 单规格
        if (!this.isSpecs) {
          specsType = ''
          specsValue = ''
        }
        // 校验规格值是否在下拉框内
        const hasSpecValue = await this.handleHasSpecValue() 
        return {
          downShelfSpecs: JSON.stringify(this.downShelfSpecs),
          specsType,
          specsValue,
          specsSwitch: this.isSpecs ? 1 : 0,
          hasSpecValue
        }
      },

      //规格值首字母大写
      inputValueblur(val) {
        if (!val) return
        let reg = new RegExp('^[\u4E00-\u9FA5]*$')
        if (reg.test(val)) {
          return (this.inputValue = '')
        }
        if (!reg.test(val)) {
          this.inputValue = val
            .replace(/[\u4E00-\u9FA5]/g, '')
            .replace( /[\u3000-\u303F\uFF00-\uFFEF\u2018-\u201D\u2026]/g,
        '')
            .replace(/\b\w+\b/g, (word) => {
              return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
        }
      },

      // 获取第一张图片
      getFirstImgUrl(urls) {
        return urls ? urls.split(',')[0] : ''
      },

      // 显示选择印刷效果图弹窗
      choseRendingImages(index, urls) {
        this.currentRendingIndex = index
        this.currentDefaultRendingList = []
        this.chooseImagesDialogVisible = true
        if (urls) {
          let _urls = urls.split(',')
          for (let i = 0; i < _urls.length; i++) {
            this.currentDefaultRendingList.push({
              url: _urls[i],
              name: '',
            })
          }
          this.currentDefaultRendingListBackups = deepCopy(this.currentDefaultRendingList)
        }
      },

      setRendingImages(data) {
        this.$set(this.specList[this.currentRendingIndex], 'image', data.urls)
        this.$set(this.specList[this.currentRendingIndex], 'imageId', '')
        this.setSpecSync()
      },

      //根据规格列表中状态为1的列让该行变为灰色
      rowClassName({ row }) {
        let flag = this.getShelfFromDownShelfSpecs(row)
        if (flag && this.pageType == 'edit') {
          // 变颜色的条件
          return 'rowName' // 这个return的就是样式 可以是color 也可以是backgroundColor
        }
      },

      // 清空数据
      clearData() {
        this.form.specName = ''
        this.tableData = []
        this.specList = []

        this.specData.forEach((it) => {
          this.$set(it, 'disabled', false)
          this.$set(it, 'value', it.specOption)
        })
        this.setSpecSync()
      },
      // 判断是否有规格值不在规格下拉框中
      handleHasSpecValue() {
       return new Promise((resolve, reject) => {
        if(this.productTypeValue === 2 && !this.iseditBol){
          for(let k of this.tableData){
            let speceArr = k.specOption.map(item=>item.value)
            if(k.value.length){
              for(let i of k.value){
                if(!speceArr.includes(i)){
                  resolve(true)
                  return this.$message.warning(this.lang === 'zh' ? `产品类型发生变化，请删除以下订制品的规格值：${i}` :  `The product type has changed. Please delete the following customized product specification values:${i}`)
                }
              }
            }
          }
          resolve(false)
        }else{
          resolve(false)
        }
       
       })
      },
    },

    beforeDestroy() {
      // 关闭$bus
      this.$Bus.$off('specProMsg')
    },
    watch: {
      'form.specName': function (val) {
        if (!val) {
          this.$Bus.$emit('clearSpec')
        }
      },
      // 监听产品类型的切换相应的规格值取不同的值如果选择过了禁用当前规格下拉框的已选择的值
      productTypeValue(newVal){
        this.tableData = this.tableData.map(item=>{
          item.specOption = newVal === 2 ? item.specStandardsOption : item.specCustomizationOption
          for(let k of item.specOption){
            k.disabled = false
            if(item.value.includes(k.value))k.disabled = true
          }
          return item
        })
      }
    },
  }
</script>

<style scoped lang="scss">
  .pro-container {
    .left-h6 {
      display: flex;
      font-size: 16px;
      font-family: 'PingFang Bold';
    }

    .left-h6::before {
      content: '';
      display: block;
      height: 16px;
      width: 2px;
      background: #282c34;
      margin-right: 5px;
      margin-top: 4px;
    }

    .right-content {
      height: 100%;
      border: 1px solid #e4e7ed;
      padding: 20px;
      border-radius: 3px;
    }
  }

  .draggable-row {
    >div {
      text-align: left;
    }
  }

  ::v-deep {
    .el-tag {
      margin-top: 5px;
    }

    .el-tag+.el-tag {
      margin-left: 10px;
    }

    .saveTagInput-row {
      .el-input--small .el-input__inner {
        height: 32px !important;
      }
    }

    .down-tag {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .wrapper-box {
    .wrapper-add {
      display: inline-block;
      width: 50px;
      height: 50px;
      text-align: center;
      border: 1px dotted #c0cbda;
      border-radius: 3px;
      cursor: pointer;

      .el-row {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          font-size: 12px;
          color: #9e9e9e;
        }

        i {
          color: #9e9e9e;
        }
      }
    }
  }

  .sort {
    text-align: center;
    cursor: all-scroll;

    i {
      font-size: 18px;
    }
  }

  .iconfont {
    cursor: pointer;
    font-size: 14px;
  }

  .icon-xiajia {
    color: #1890ff;
  }

  .ml-10 {
    margin-left: -10px;
  }
</style>
<style>
  .rowName {
    background-color: #f5f7fa !important;
  }
</style>
