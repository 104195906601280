<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
    v-if="dialogFormVisible"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-row>
        <el-col :span="24">
          <el-form-item label="规格名称" prop="nameCn">
            <el-input v-model="form.nameCn" placeholder="请输入" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item style>
            <template slot="label">
              <i style="color: #ff4d4f">*</i>
              规格值
            </template>
            <el-input
              v-model="tagValue"
              onkeyup="this.value = this.value.replace(/[,|，]/g,'');"
              placeholder="请输入"
              @input="tagValueblur"
              @keyup.enter.native="addClick"
              v-filterSpace
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button @click="addClick" class="pub-h-36">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="mt10" style="margin-left: 90px">
      <el-tag
        v-for="tag in tags"
        :key="tag"
        size="medium"
        closable
        @close="handleClose(tag)"
      >
        {{ tag }}
      </el-tag>
    </el-row>

    <template #footer>
      <el-row class="text-center">
        <el-button type="primary" :loading="loading" @click="save">
          确认
        </el-button>
        <el-button @click="close">取消</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { insertPO, updatePO } from '@/api/product/productSetting'
  export default {
    name: 'SpecModel',
    data() {
      return {
        loading: false,
        title: '规格设置',
        form: {
          nameCn: '',
          nameEn: '',
          productSettingId: '',
          type: '',
          value: '',
        },
        rules: {
          nameCn: [{ required: true, trigger: 'blur', message: '请输入' }],
        },
        dialogFormVisible: false,
        tagValue: '',
        tags: [],
      }
    },
    created() {},
    methods: {
      //规格值首字母大写
      tagValueblur(val) {
        if (!val) return
        let reg = new RegExp('^[\u4E00-\u9FA5]*$')
        if (reg.test(val)) {
          return (this.tagValue = '')
        }
        if (!reg.test(val)) {
          this.tagValue = val
            .replace(/[\u4E00-\u9FA5]/g, '')
            .replace( /[\u3000-\u303F\uFF00-\uFFEF\u2018-\u201D\u2026]/g,
        '')
            .replace(/\b\w+\b/g, (word) => {
              return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
        }
      },
      //展示弹框
      showAddEdit(row) {
        if (!row) {
          this.form.type = 1
        } else {
          this.form = Object.assign({}, row)
          this.tags = this.form.value.split(',')
        }
        this.dialogFormVisible = true
      },
      // 关闭
      close() {
        this.tagValue = ''
        this.tags = []
        this.$refs['form'].resetFields()
        this.form = {
          nameCn: '',
          nameEn: '',
          productSettingId: '',
          type: '',
          value: '',
        }
        this.dialogFormVisible = false
      },
      // 新增
      addClick() {
        this.tagValue = this.tagValue.trim()
        if (this.tagValue === '') {
          this.$baseMessage(
            '规格值不能为空',
            'warning',
            false,
            'erp-hey-message-warning'
          )
        } else {
          let isDuplicate = this.tags.some(
            (item) => item.toLowerCase() === this.tagValue.toLowerCase()
          );
          if (isDuplicate) {
            this.$baseMessage(
              `规格值${this.tagValue}已存在，请勿重复添加！`,
              'warning',
              false,
              'erp-hey-message-warning'
            )
            this.tagValue = '' 
            return
          }
          this.tags.push(this.tagValue)
        }
        this.tagValue = ''
      },
      // 标签删除
      handleClose(tag) {
        this.tags.splice(this.tags.indexOf(tag), 1)
      },
      // 保存
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.tags.length === 0) {
              return this.$baseMessage(
                '规格值不能为空',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              this.form.value = this.tags.join(',')
              let response
              this.loading = true
              if (this.form.productSettingId === '') {
                response = await insertPO(this.form)
                this.loading = false
                if ((response.code = '000000')) {
                  this.$baseMessage(
                    '新增成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                }
              } else {
                response = await updatePO(this.form)
                this.loading = false
                if ((response.code = '000000')) {
                  this.$baseMessage(
                    '编辑成功',
                    'success',
                    false,
                    'erp-hey-message-success'
                  )
                }
              }

              this.$emit('spec', this.form.type)
              this.close()
            }
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .el-tag {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
</style>
