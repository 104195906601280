<template>
  <div class="text-left">

    <!-- 产品编码 -->
    <p :title="product.sku">产品编码：{{ product.sku || '--' }}</p>
    <!-- 产品名称 -->
    <p :title="product.productEn">产品名称：{{ product.productEn || '--' }}</p>
    <!-- 规格 -->
    <p :title="product.productSpecs">
      规格：{{
        product.productSpecs == 'undefined'
          ? '--'
          : product.productSpecs || '--'
      }}
    </p>
    <!-- 产品运营 -->
    <p :title="product.productBelonger">产品运营：{{ product.productOperation }}</p>
  </div>
</template>

<script>
  export default {
    name: 'ProductInfo',
    props: {
      product: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .text-left {
    text-align: left;
    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
