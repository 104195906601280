import { format } from 'kits'
//  获取接口相关参数
export function getAddressList(
  product,
  cargoDistributionVOS,
  receivingAddressVOS
) {
  // 过滤cargoDistributionVOS
  const cargoDistributions = cargoDistributionVOS?.filter(
    (item) => item.orderProductUniqueId === product.orderProductUniqueId
  )
  // 匹配地址
  const addressList = []
  cargoDistributions.forEach((item) => {
    const execAddress = receivingAddressVOS?.find(
      (address) => address.addressId === item.addressId
    )
    if (execAddress) {
      addressList.push({
        city: execAddress.city,
        country: execAddress.nation,
        postcode: execAddress.postcode,
        province: execAddress.province,
        quantTotle: item.quantTotle,
        street: execAddress.street,
        address: execAddress.address,
      })
    }
  })
  return addressList
}

// 获取顺延日期
export function getDayDiff(createTime) {
  // 获取当前时间
  const now = new Date()

  // 将 createTime 和 targetTime 转换为 Date 对象
  const createDate = new Date(createTime)

  // 计算 createTime 和当前时间的天数差
  const timeDiff = now.setHours(0, 0, 0, 0) - createDate.setHours(0, 0, 0, 0)
  const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)) // 转换为天数
  return dayDiff
}

function addDaysToTarget(createTime, targetTime) {
  // 将 createTime 和 targetTime 转换为 Date 对象
  const targetDate = new Date(targetTime)

  // 计算 createTime 和当前时间的天数差

  const dayDiff = getDayDiff(createTime)

  // 在 targetDate 上加上 dayDiff 天
  // targetDate.setDate(targetDate.getDate() + dayDiff)

  return format(targetDate, dayDiff)
}

// 批量对相关日期进行延期
export function batchDelayDate(products, createTime) {
  products = products.map((pro) => {
    const planShippingDate = addDaysToTarget(
      createTime || pro.createTime,
      pro.productExtend.initPlanShippingDate
    )
    pro.productExtend.planShippingDate = planShippingDate
    if (pro.productExtend.firmFlag === 0) {
      const customerDeliveryDate = addDaysToTarget(
        pro.createTime,
        pro.productExtend.initCustomerDeliveryDate
      )
      pro.productExtend.customerDeliveryDate = customerDeliveryDate
    }
    return pro
  })
}

// 校验运输方式是否会导致延期
export function isShippingDelay(products) {
  return products.some((pro) => pro.shippingDelay === 1)
}
