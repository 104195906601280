<template>
  <div class="custom-wrap">
    <div class="custom-top">
      <div class="flex">
        <el-page-header title="返回" :content="code" @back="goBack()" v-if="!$route.query.noReturn"></el-page-header>
        <div v-else class="no-return">
          {{ code }}
        </div>
      </div>

      <div>
        <el-button plain @click="printProcess" v-allowed="['OUTSOURCE:PROCESS:PRINT']">
          打印加工单
        </el-button>
        <el-button plain @click="printMark">打印箱唛</el-button>
        <el-button plain @click="edit" v-show="details.optionStatus === 1">
          编辑
        </el-button>
        <el-button v-show="details.status === 1" plain @click="cancellation" type="danger">
          作废
        </el-button>
      </div>
    </div>

    <el-tabs v-model="active">
      <!-- 订单信息 -->
      <el-tab-pane name="1" label="订单信息">
        <div class="custom-detail">
          <el-row>
            <el-col>
              <em>销售单号：</em>
              <span class="page-link" @click="orderCodeClick(details.salesOrderCode)">
                {{ details.salesOrderCode }}
              </span>
            </el-col>
            <el-col>
              <em>供应商：</em>
              <span>{{ details.supplierName }}</span>
            </el-col>
            <el-col>
              <em>成品入库：</em>
              <span>{{ details.inWarehouseName }}</span>
            </el-col>
            <el-col>
              <em>创建人：</em>
              <span>{{ details.creator }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <em>创建时间：</em>
              <span>{{ details.createTime }}</span>
            </el-col>
            <el-col style="width: 75%">
              <em>备&nbsp;&nbsp;&nbsp;注：</em>
              <span>
                <div v-if="details.remark">
                  <el-tooltip class="item" effect="dark" :content="details.remark" placement="top-start">
                    <p class="ell">{{ details.remark || '--' }}</p>
                  </el-tooltip>
                </div>
                <div v-else>--</div>
              </span>
            </el-col>
          </el-row>
        </div>

        <h4>产品信息</h4>
        <el-table :data="details.processProductVOList" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="产品信息" width="260">
            <template slot-scope="scope">
              <div class="text-left">
                <p>产品编码：{{ scope.row.skuId }}</p>
                <el-tooltip class="item" effect="dark" :content="scope.row.productCn" placement="top-start">
                  <p class="ell">产品名称：{{ scope.row.productCn }}</p>
                </el-tooltip>
                <p>
                  客户代表：{{
                  details.businessUserName +
                  '(' +
                  details.businessUserNameEn +
                  ')'
                  }}
                </p>
                <p>
                  产品运营：
                  {{
                  scope.row.productOperation
                  ? scope.row.productOperation +
                  '(' +
                  scope.row.productOperationEn +
                  ')'
                  : '--'
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <!-- 计划装运日期/客户交期 -->
          <el-table-column align="center" width="260" label="计划装运日期/客户交期">
            <template slot-scope="{ row }">
              <div style="text-align:left">
                <span>计划装运日期</span>
                <el-tooltip placement="top">
                  <div slot="content">订单预计交付给国际物流或国际快递的<br/>日期，并以装运地的时区时间为准。</div>
                  <i class="el-icon-question"/>
                </el-tooltip>
                <span>：</span>
                <span>{{ row.productExtend && row.productExtend.planShippingDate ? row.productExtend.planShippingDate : '--' }}</span>
              </div>
              <div style="text-align:left">
                <span>客户交期</span>
                <el-tooltip placement="top">
                  <div slot="content">
                    1、订单交付到客户指定收货地点的约定日期，并以交货<br/>
                    地的时区时间为准，需要全力确保与客户约定的交期。<br/>
                    2、「Firm 交期」指的是明确的客户交期，需要严格地<br/>
                    满足此交期，否则会面临更加严重的违约责任。
                  </div>
                  <i class="el-icon-question"/>
                </el-tooltip>
                <span>：</span>
                <span>{{ row.productExtend && row.productExtend.customerDeliveryDate ? row.productExtend.customerDeliveryDate : '--' }}</span>
                <el-tag v-if="row.productExtend && row.productExtend.firmFlag == 1" size="mini" type="warning" effect="dark" style="margin-left:5px">Firm 交期</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="产品颜色" prop="productSpecs"></el-table-column>
          <!-- <el-table-column
            label="产品材质"
            prop="productMaterial"
          ></el-table-column> -->
          <el-table-column label="订单数量" prop="processNum"></el-table-column>
          <el-table-column label="留样数量" prop="sampleNum">
            <template slot-scope="scope">
              <div>
                {{ scope.row.sampleNum || 0 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="效果图" prop="productImage">
            <template slot-scope="scope">
              <div>
                <!-- <erp-image :imgs="scope.row.productImage"></erp-image> -->
                <erp-image :imgs="scope.row.printWay=== '无需印刷'?scope.row.productImage||scope.row.imgMain:scope.row.productImage"></erp-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="印刷方式" prop="printWay"></el-table-column>
          <el-table-column label="印刷颜色" prop="printColor"></el-table-column>
          <el-table-column label="印刷尺寸" prop="printSize">
            <template slot-scope="scope">
              <div v-if="scope.row.printSize">
                <el-tooltip class="item" effect="dark" :content="scope.row.printSize" placement="top-start">
                  <div class="limit-text">
                    {{ scope.row.printSize }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="印刷描述">
            <template slot-scope="scope">
              <div v-if="scope.row.printDesc">
                <el-tooltip class="item" effect="dark" :content="scope.row.printDesc" placement="top-start">
                  <div class="limit-text">
                    {{ scope.row.printDesc }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="包装要求">
            <template slot-scope="scope">
              <div v-if="scope.row.packaging">
                <el-tooltip class="item" effect="dark" :content="scope.row.packaging" placement="top-start">
                  <div class="limit-text">
                    {{ scope.row.packaging }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="是否急单" prop="urgent">
            <template slot-scope="scope">
              {{ scope.row.urgent == 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="内部备注">
            <template slot-scope="scope">
              <div v-if="scope.row.innerRemark">
                <el-tooltip class="item" effect="dark" :content="scope.row.innerRemark" placement="top-start">
                  <div class="limit-text">
                    {{ scope.row.innerRemark || '--' }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="工厂交期" prop="expectDeliveryDate">
            <template slot-scope="scope">
              {{ utils.cutDate(scope.row.expectDeliveryDate) }}
            </template>
          </el-table-column>
        </el-table>

        <h4>物料信息</h4>
        <el-table :data="details.processProductVOList" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="物料编码" prop="skuId"></el-table-column>
          <el-table-column label="物料名称" prop="productCn"></el-table-column>
          <el-table-column label="规格" prop="productSpecs"></el-table-column>
          <el-table-column label="损耗率">
            <template slot-scope="scope">{{ scope.row.loseRatio }}%</template>
          </el-table-column>
          <el-table-column label="出库仓库" prop="warehouseOutName"></el-table-column>
          <el-table-column label="入库批次" prop="outLots">
            <template slot-scope="scope">
              <div v-for="(item, i) in scope.row.outLots" :key="i">
                <span class="ell">
                  {{ item.lotNo + '(' + item.num + ')' }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <h4>附件</h4>
        <FilePreview :flieList="details.annexFiles"></FilePreview>

        <div class="flex-btn mt20 mb15">
          <h4>箱唛</h4>
          <el-button @click="printMark">预览箱唛</el-button>
          <el-button @click="downloadMarks(marks)">下载箱唛</el-button>
        </div>
        <p style="white-space: pre-wrap">{{ JSON.parse(marks) }}</p>
      </el-tab-pane>

      <!-- 加工/成本明细 -->
      <el-tab-pane name="2" label="加工/成本明细">
        <h4 style="margin-top: 0">加工费用</h4>
        <el-table :data="porcessInfo.productFeeInfoVOList" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="产品信息" width="240">
            <template slot-scope="scope">
              <div class="text-left">
                <p>产品编码：{{ scope.row.skuId }}</p>
                <el-tooltip class="item" effect="dark" :content="scope.row.productCn" placement="top-start">
                  <p class="ell">产品名称：{{ scope.row.productCn }}</p>
                </el-tooltip>
                <p>
                  客户代表：{{
                  details.businessUserName +
                  '(' +
                  details.businessUserNameEn +
                  ')'
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="productSpecs"></el-table-column>
          <el-table-column label="印刷方式" prop="printWay"></el-table-column>
          <el-table-column label="印刷颜色" prop="printColor"></el-table-column>
          <el-table-column label="订单数量" prop="processNum"></el-table-column>
          <el-table-column label="留样数量" prop="sampleNum"></el-table-column>
          <el-table-column label="加工费单价" prop="feeUnitPrice"></el-table-column>
          <el-table-column label="版费" prop="editionFee"></el-table-column>
          <el-table-column label="其它费用" prop="otherFee"></el-table-column>
          <el-table-column label="费用说明">
            <template slot-scope="scope">
              <div v-if="scope.row.feeDesc">
                <el-tooltip class="item" effect="dark" :content="scope.row.feeDesc" placement="top-start">
                  <div class="limit-text">
                    {{ scope.row.feeDesc }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="折扣金额" prop="discountAmount"></el-table-column>
          <el-table-column label="折扣说明">
            <template slot-scope="scope">
              <div v-if="scope.row.discountDesc">
                <el-tooltip class="item" effect="dark" :content="scope.row.discountDesc" placement="top-start">
                  <div class="limit-text">
                    {{ scope.row.discountDesc || '--' }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="合计费用" prop="totalFee"></el-table-column>
        </el-table>

        <h4>整体进度</h4>
        <el-table :data="porcessInfo.productProgressInfoVOList" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="产品信息" prop="xxx" width="240">
            <template slot-scope="scope">
              <div class="text-left">
                <p>产品编码：{{ scope.row.skuId }}</p>
                <el-tooltip class="item" effect="dark" :content="scope.row.productCn" placement="top-start">
                  <p class="ell">产品名称：{{ scope.row.productCn }}</p>
                </el-tooltip>
                <p>
                  客户代表：{{
                  details.businessUserName +
                  '(' +
                  details.businessUserNameEn +
                  ')'
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="productSpecs"></el-table-column>
          <el-table-column label="要求加工数量">
            <template slot-scope="scope">
              {{ scope.row.processNum + scope.row.sampleNum }}
            </template>
          </el-table-column>
          <el-table-column label="领料进度">
            <template slot-scope="scope">
              {{ scope.row.matterStatus | matterStatusTypeName }}
            </template>
          </el-table-column>
          <el-table-column label="领料数量" prop="pickingNum"></el-table-column>
          <el-table-column label="退料数量" prop="returnNum"></el-table-column>
          <el-table-column label="用料数量" prop="useNum"></el-table-column>
          <el-table-column label="加工状态">
            <template slot-scope="scope">
              {{ scope.row.pickingStatus | pickingStatusTypeName }}
            </template>
          </el-table-column>
          <el-table-column label="合格数量" prop="qualityNum"></el-table-column>
          <el-table-column label="报废数量" prop="scrapNum"></el-table-column>
          <el-table-column label="验货状态">
            <template slot-scope="scope">
              {{ scope.row.inspectionStatus | inspectionStatusTypeName }}
            </template>
          </el-table-column>
          <el-table-column label="入库状态">
            <template slot-scope="scope">
              {{ scope.row.stockStatus | stockStatusTypeName }}
            </template>
          </el-table-column>
          <el-table-column label="入库数量">
            <template slot-scope="scope">
              {{ scope.row.stockNum || '--' }}
            </template>
          </el-table-column>
        </el-table>

        <h4>加工数据</h4>
        <el-table :data="porcessInfo.productProcessInfoVOList" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="产品信息" width="240">
            <template slot-scope="scope">
              <div class="text-left">
                <p>产品编码：{{ scope.row.skuId }}</p>
                <el-tooltip class="item" effect="dark" :content="scope.row.productCn" placement="top-start">
                  <p class="ell">产品名称：{{ scope.row.productCn }}</p>
                </el-tooltip>
                <p>
                  客户代表：{{
                  details.businessUserName +
                  '(' +
                  details.businessUserNameEn +
                  ')'
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="productSpecs"></el-table-column>
          <el-table-column label="要求加工数量">
            <template slot-scope="scope">
              {{ scope.row.processNum + scope.row.sampleNum }}
            </template>
          </el-table-column>
          <el-table-column label="合格数量" prop="qualityNum"></el-table-column>
          <el-table-column label="报废数量" width="160">
            <template slot-scope="scope">
              <div class="text-left">
                <p>
                  报&nbsp;废&nbsp;总&nbsp;数：
                  <span class="red">{{ scope.row.scrapNum }}</span>
                </p>
                <p>
                  制&nbsp;程&nbsp;损&nbsp;耗：
                  <span>{{ scope.row.processLoseNum }}</span>
                </p>
                <p>
                  原&nbsp;料&nbsp;问&nbsp;题：
                  <span>{{ scope.row.rawScrapNum }}</span>
                </p>
                <p>
                  供应商问题：
                  <span>{{ scope.row.supplierScrapNum }}</span>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="用料数量" prop="useNum"></el-table-column>
          <el-table-column label="规定损耗率">
            <template slot-scope="scope">{{ scope.row.loseRatio }}%</template>
          </el-table-column>
          <el-table-column label="实际损耗率">
            <template slot-scope="scope">
              <div v-if="scope.row.realLoseRatio">
                {{ (scope.row.realLoseRatio * 100).toFixed(2) }}%
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="报废率">
            <template slot-scope="scope">
              <div v-if="scope.row.scrapRatio">
                {{ (scope.row.scrapRatio * 100).toFixed(2) }}%
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="期望交期">
            <template slot-scope="scope">
              {{ utils.cutDate(scope.row.expectDeliveryDate) }}
            </template>
          </el-table-column>
          <el-table-column label="实际完成时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.completeTime }}
            </template>
          </el-table-column>
        </el-table>

        <h4>成本核算</h4>
        <el-table :data="porcessInfo.productCostVOList" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="产品信息">
            <template slot-scope="scope">
              <div class="text-left">
                <p>产品编码：{{ scope.row.skuId }}</p>
                <el-tooltip class="item" effect="dark" :content="scope.row.productCn" placement="top-start">
                  <p class="ell">产品名称：{{ scope.row.productCn }}</p>
                </el-tooltip>
                <p>
                  客户代表：{{
                  details.businessUserName +
                  '(' +
                  details.businessUserNameEn +
                  ')'
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="productSpecs"></el-table-column>
          <el-table-column label="用料数量" prop="useNum"></el-table-column>
          <el-table-column label="用料成本(￥)">
            <template slot-scope="scope">
              {{ scope.row.useCost ? scope.row.useCost.toFixed(3) : '--' }}
            </template>
          </el-table-column>
          <el-table-column label="总加工费(￥)">
            <template slot-scope="scope">
              {{
              scope.row.processTotalFee
              ? scope.row.processTotalFee.toFixed(3)
              : '--'
              }}
            </template>
          </el-table-column>
          <el-table-column label="总成本(￥)">
            <template slot-scope="scope">
              {{ scope.row.totalCost ? scope.row.totalCost.toFixed(3) : '--' }}
            </template>
          </el-table-column>
          <el-table-column label="工厂报废物料成本(￥)">
            <template slot-scope="scope">
              {{ scope.row.scrapCost ? scope.row.scrapCost.toFixed(3) : '--' }}
            </template>
          </el-table-column>
          <el-table-column label="成品入库成本(￥)">
            <template slot-scope="scope">
              {{
              scope.row.warehouseInCost
              ? scope.row.warehouseInCost.toFixed(3)
              : '--'
              }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- 出库/入库记录 -->
      <el-tab-pane name="3" label="出库/入库记录">
        <el-table :data="warehouseInfo" :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }" border>
          <el-table-column label="单据号" width="130">
            <template slot-scope="scope">
              <div class="page-link" @click="goWarehouse(scope.row)">
                {{ scope.row.warehouseCode }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="typeName"></el-table-column>
          <el-table-column label="仓库" prop="warehouseName"></el-table-column>
          <el-table-column label="产品编码" prop="skus"></el-table-column>
          <el-table-column label="产品名称" prop="productNames"></el-table-column>
          <el-table-column label="产品规格" prop="productSpecs"></el-table-column>
          <el-table-column label="数量" prop="num"></el-table-column>
          <el-table-column label="成本">
            <template slot-scope="scope">
              {{ scope.row.totalPrice && scope.row.totalPrice.toFixed(3) }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="statusName"></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane name="4" label="操作日志">
        <OperateLogs
          v-if="active == 4"
          :bizId="id"
          :searchParams="{ moduleCode: '10007' }"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { encode } from 'kits'
  import { OutProcessing } from '@/core/interactors/purchase/outsourceProcess'
  import Dicts from './dict/index'
  import { downloadMarksCommon } from '@/utils/utils.js'
  import FilePreview from '@/components/FilePreview'
  import OperateLogs from '@/components/operate-logs'
  export default {
    components: { FilePreview, OperateLogs },
    data() {
      return {
        id: '',
        code: '',
        active: '1',
        marks: null, // 箱唛
        printMarks: null, // 打印箱唛
        details: {}, // 订单详情
        porcessInfo: {}, // 加工成本明细
        warehouseInfo: [], // 出库/入库记录
      }
    },
    filters: {
      // 加工状态
      pickingStatusTypeName(n) {
        return Dicts.pickingStatusTypeName(n)
      },
      // 验货状态
      inspectionStatusTypeName(n) {
        return Dicts.inspectionStatusTypeName(n)
      },
      // 领料进度
      matterStatusTypeName(n) {
        return Dicts.matterStatusTypeName(n)
      },
      // 入库状态
      stockStatusTypeName(n) {
        return Dicts.stockStatusTypeName(n)
      },
    },
    created() {
      this.id = this.$route.query.id
      this.code = this.$route.query.code
      this.getDetail()
    },
    methods: {
      // 获取详情
      async getDetail() {
        // 订单详情
        const orderRes = await OutProcessing.processGetDetail(this.id)
        // 加工/成本明细详情
        const porcessRes = await OutProcessing.getProductInfo(this.id)
        // 加工/成本明细详情
        const warehouseRes = await OutProcessing.getWarehouseProcessOrderInfoVO(
          this.code
        )

        if (orderRes && orderRes.code === '000000') {
          this.details = orderRes.data
          this.getMarks()
        }
        if (porcessRes && porcessRes.code === '000000') {
          this.porcessInfo = porcessRes.data
        }
        if (warehouseRes && warehouseRes.code === '000000') {
          this.warehouseInfo = warehouseRes.data
        }
      },

      // 获取箱唛
      async getMarks() {
        let [onlyIds, skuIds] = [[], []]
        this.details.processProductVOList.forEach((item) => {
          onlyIds.push(item.orderProductUniqueId)
          skuIds.push(item.skuId)
        })
        const res = await OutProcessing.processPrintOrderMarks({
          orderId: this.details.salesOrderId,
          orderProductUniqueId: onlyIds,
          skuIds: skuIds,
          printType: 3,
          checkFlag: 0,
        })

        if (res && res.code === '000000') {
          let [_marks, _arr] = [res.data?.marksShowVOS, []]

          if (_marks?.length) {
            for (let i = 0; i < _marks.length; i++) {
              for (let n = 0; n < _marks[i].length; n++) {
                if (_marks[i][n].markPrefix === 'Made in China') {
                  _arr.push(
                    _marks[i][n].markPrefix +
                    '\n\n' +
                    (i + 1 != _marks.length ? '----------------' : '')
                  )
                } else if (
                  ['Quantity', 'CTN SPEC', 'G.W', 'CTN.NO'].includes(
                    _marks[i][n].markPrefix
                  )
                ) {
                  _arr.push(
                    _marks[i][n].markPrefix +
                    ':                ' +
                    _marks[i][n].markSuffix
                  )
                } else {
                  // 地址中存在的,需要保留，此处将其转化成&&&字符，再通过replace转化
                  _arr.push(
                    _marks[i][n].markPrefix +
                    ':' +
                    _marks[i][n].markSuffix.replace(/,/g, '&&&')
                  )
                }
              }
            }
            this.printMarks = _marks
            this.marks = JSON.stringify(
              _arr.join().replace(/,/g, '\n').replace(/&&&/g, ',')
            )
          } else {
            this.$message.warning('箱唛内容为空！')
          }
        }
      },

      // 打印加工单
      printProcess() {
        if (this.details.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        let _ids = []

        this.details.processProductVOList.forEach((item) =>
          _ids.push(item.processOrderId)
        )
        const routeData = this.$router.resolve({
          path: `/print/processSheet?ids=${_ids.join(',')}&type=1`,
        })
        window.open(routeData.href, '_blank')
      },

      // 打印箱唛
      async printMark() {
        if (this.details.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        if (this.marks) {
          window.open(
            window.location.origin +
            `#/print/orderShipMark?QRCode=${true}&rendImgDisplayed=${false}&content=${encode(this.printMarks)}&type=1`,
            '_blank'
          )
        } else {
          this.$message.warning('箱唛内容为空！')
        }
      },

      // 出入库跳转
      goWarehouse({ warehouseId, type }) {
        if (type === 1) {
          this.$router.push({
            name: 'inbound-sheet-detail',
            query: {
              warehouseInId: warehouseId,
            },
          })
        } else {
          this.$router.push({
            name: 'outbound-sheet-detail',
            query: {
              warehouseOutId: warehouseId,
            },
          })
        }
      },

      // 编辑
      edit() {
        if (this.details.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        this.$router.push(
          `/purchase/outsourceProcess/outsource-add?id=${this.id}`
        )
      },

      // 作废
      cancellation() {
        if (this.details.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        if (this.details.optionStatus == 0) {
          this.$message.warning(
            '该加工单下产品已经开始加工，请联系加工厂进行异常完工'
          )
          return
        }

        this.$confirm('此操作将作废该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const res = await OutProcessing.updateDelete(this.id)

          if (res && res.code === '000000') {
            this.getDetail()
            this.$message({
              type: 'success',
              message: '操作成功',
            })
          }
        })
      },

      // 下载箱唛
      downloadMarks() {
        if (this.marks) {
          downloadMarksCommon(JSON.parse(this.marks))
        } else {
          this.$message.warning('箱唛内容为空！')
        }
      },

      // 销售单号跳转
      orderCodeClick(code) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: { orderCode: code, noReturn: true },
        })
        window.open(routeData.href, '_blank')
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="scss" scoped></style>
