<template>
  <div class="custom-container">
    <el-radio-group v-model="tabsType" class="mr10" @change="tabsTypeChange" v-if="isShowTab">
      <el-radio-button :label="1">订单明细</el-radio-button>
      <el-radio-button :label="2">产品明细</el-radio-button>
    </el-radio-group>
    <erp-search ref="erpsearch" :searchFormConfig="searchFormConfig"
      :value="tabsType == 1 ? filterOrderForm : filterProductForm" @enter-search="onButtonSearch" class="mt15"
      :key="searchKey"></erp-search>

    <div class="btn-box">
      <div class="order-btn" v-if="tabsType == 1">
        <el-button size="small" type="primary" @click="addProcess('order')">
          新增
        </el-button>
        <el-button size="small" v-allowed="['OUTSOURCE:PROCESS:PRINT']" @click="printProcess(1)">
          打印加工单
        </el-button>
      </div>
      <div class="product-btn" v-if="tabsType == 2">
        <el-button size="small" type="primary" @click="addProcess('product')">
          新增
        </el-button>
        <el-button size="small" @click="printProcess(2)" v-allowed="['OUTSOURCE:PROCESS:PRINT']">
          打印加工单
        </el-button>
        <el-button size="small" @click="outbound(0)" v-allowed="['OUTSOURCE:PROCESS:APPLY']">
          领料出库
          <el-tooltip class="item" effect="dark" content="创建加工单时将自动生成领料出库单，需要多领时请操作此按钮" placement="top">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </el-button>
        <el-button size="small" @click="setStartWork" v-allowed="['OUTSOURCE:PROCESS:START']">
          开始加工
        </el-button>
        <el-button size="small" @click="outbound(2)" v-allowed="['OUTSOURCE:PROCESS:RETURN']">
          退料入库
        </el-button>
        <el-button size="small" @click="setFinishWork" v-allowed="['OUTSOURCE:PROCESS:COMPLETED']">
          加工完成
        </el-button>

        <el-button size="small" @click="cargo" v-allowed="['OUTSOURCE:PROCESS:REMIND']">
          验货通知
          <el-tooltip class="item" effect="dark" content="已完工的加工单将自动生成验货单，此按钮用于加工单验货不通过时，再次增加加工验货单" placement="top">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </el-button>
        <el-button size="small" @click="outbound(1)" v-allowed="['OUTSOURCE:PROCESS:INBOUND']">
          加工入库
        </el-button>
        <el-dropdown size="small" split-button @command="changeShipStatus" v-allowed="['OUTSOURCE:PROCESS:SHIPSTATUS']">
          {{ shipStatus }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="标记已出运">标记已出运</el-dropdown-item>
            <el-dropdown-item command="标记未出运">标记未出运</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button @click="exportData" size="small" v-allowed="['OUTSOURCE:PROCESS:EXPORT']" style="margin-left: 10px">
          导出
        </el-button>
        <el-button @click="handleadJustmentExpenses" size="small" v-allowed="['OUTSOURCE:PROCESS:COST']">
          费用调整
        </el-button>
      </div>
      <div class="right-btn">
        <el-dropdown size="small" split-button @command="changeTimeOrder">
          {{ timeOrder }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="按【创建时间】正序">
              按【创建时间】正序
            </el-dropdown-item>
            <el-dropdown-item command="按【创建时间】倒序">
              按【创建时间】倒序
            </el-dropdown-item>
            <el-dropdown-item command="按【期望交期】正序" v-if="tabsType == 2">
              按【期望交期】正序
            </el-dropdown-item>
            <el-dropdown-item command="按【期望交期】倒序" v-if="tabsType == 2">
              按【期望交期】倒序
            </el-dropdown-item>
            <el-dropdown-item command="按【完工时间】正序" v-if="tabsType == 2">
              按【完工时间】正序
            </el-dropdown-item>
            <el-dropdown-item command="按【完工时间】倒序" v-if="tabsType == 2">
              按【完工时间】倒序
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 自定义展示列 -->
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <erp-draggable v-bind="dragOptions" :list="customList">
            <el-checkbox v-for="(item, index) in customList" :key="item + index" v-model="item.isShow"
              :disabled="item.checkable === false" :label="item.label">
              {{ item.label }}
            </el-checkbox>
          </erp-draggable>
          <!-- 保存自定义显示列 -->
          <div class="save-btn">
            <el-button type="primary" class="ml10" size="mini" plain @click="operateSave()">
              保存
            </el-button>
          </div>
          <template #reference>
            <el-tooltip effect="dark" content="列表可拖动排序" placement="top-start">
              <el-button plain type="primary" class="ml10">
                自定义显示
              </el-button>
            </el-tooltip>
          </template>
        </el-popover>
      </div>
    </div>
    <div class="status-list mt10" v-if="tabsType == 2">
      <el-radio-group v-model="status" @change="onButtonSearch">
        <el-radio v-for="item in statusList" :key="item.value" :label="item.value">
          {{ item.label }}
        </el-radio>
      </el-radio-group>
    </div>
    <div class="table mt10">
      <ErpTable :page-number.sync="pageNo" :page-size.sync="pageLe" :table-data="tableData" :total="total"
        @query="query" class="table-fixed" @selection-change="setSelectRows" :key="tableKey"
        :extraHeight="tabsType == 1 ? 90 : 121" tooltip-effect="dark" :row-class-name="urgentClassName">
        <el-table-column align="center" type="selection" width="55" fixed="left" />
        <el-table-column align="center" label="加工单号" prop="processOrderCode" width="150" fixed="left">
          <template #default="{ row }">
            <div class="page-link" @click="detail(row)">
              {{ row.processOrderCode }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in finallyColumns" :key="index" :label="item.label" :min-width="120"
          :resizable="false" align="center" :prop="item.prop" :width="item.width" :class-name="item.prop"
          show-overflow-tooltip>
          <template #default="{ row }">
            <!-- 销售单号 -->
            <div v-if="item.prop === 'salesOrderCode'" class="page-link" @click="orderDetail(row)">
              {{ row.salesOrderCode }}
            </div>
            <!-- 运输类型 -->
            <div v-else-if="item.prop === 'shippingMethodType'">
              <!-- 有功能权限且加工单状态为未加工或加工中可以编辑 -->
              <div v-if="hasTransRight && [1, 2].includes(row.pickingStatus)">
                <el-select @change="selectTransType($event, row.processProductId)" v-model="row.shippingMethodType">
                  <el-option v-for="(item, i) in shippingMethodTypeOptions" :key="i" :label="item.label"
                    :value="item.value" />
                </el-select>
              </div>
              <div v-else>
                {{ row.shippingMethodType | shippingMethodTypeName }}
              </div>
            </div>
            <!-- 预计生产时间 -->
            <div v-else-if="item.prop === 'productionTime'">
              <!-- 有功能权限且加工单状态为未加工或加工中可以编辑 -->
              <div v-if="
                  hasProductionTimeRight && [1, 2].includes(row.pickingStatus)
                ">
                <el-date-picker clearable v-model="row.productionTime" type="date" placeholder="请选择" format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd" style="width: 140px"
                  @change="changeProductionTime($event, row.processProductId)"></el-date-picker>
              </div>
              <div v-else>
                {{ row.productionTime || '--' }}
              </div>
            </div>
            <!-- 订单明细 状态 -->
            <div v-else-if="item.prop === 'status' && tabsType == 1">
              <el-tag :type="transStatusType(row.status)">
                {{ row.status | statusTypeName }}
              </el-tag>
            </div>
            <!-- 订单明细 总加工费 -->
            <div v-else-if="item.prop === 'totalProcessFee' && tabsType == 1">
              {{ row.totalProcessFee }}
            </div>
            <!-- 产品明细 加工状态-->
            <div v-else-if="item.prop === 'pickingStatus' && tabsType == 2">
              <el-tag :type="transPickingStatusType(row.pickingStatus)">
                {{ row.pickingStatus | pickingStatusTypeName }}
              </el-tag>
              <el-tooltip class="item" effect="dark" :content="row.abnormalRemark" placement="top"
                v-if="row.pickingStatus === 4">
                <i style="color: red; transform: translateX(5px)" class="el-icon-warning"></i>
              </el-tooltip>
            </div>
            <!-- 产品明细 验货状态-->
            <div v-else-if="item.prop === 'inspectionStatus' && tabsType == 2">
              <div class="inspectionStatus-sty">
                <div class="page-link" @click="goPage(row)">
                  {{ row.inspectionStatus | inspectionStatusTypeName }}
                </div>
                <el-tooltip class="item" effect="dark" content="最近一笔验货结果不合格，请及时处理" placement="top" v-if="
                    row.inspectionStatus === 3 && row.actualResults === '不合格'
                  ">
                  <i style="color: red; transform: translateX(5px)" class="el-icon-warning"></i>
                </el-tooltip>
              </div>
            </div>
            <!-- 产品明细 出运状态-->
            <div v-else-if="item.prop === 'shipFlag' && tabsType == 2">
              {{ row.shipFlag | shipFlagTypeName }}
            </div>
            <!-- 产品明细 领料进度 -->
            <div v-else-if="item.prop === 'matterStatus' && tabsType == 2">
              {{ row.matterStatus | matterStatusTypeName }}
            </div>
            <!-- 产品明细 效果图 -->
            <div v-else-if="item.prop === 'productImage' && tabsType == 2">
              <!-- <div v-if="row.productImage">
                <erp-image :imgs="row.productImage"></erp-image>
              </div> -->
              <div v-if="row.img">
                <erp-image :imgs="row.img"></erp-image>
              </div>
              <div v-else>--</div>
            </div>
            <!-- 产品明细 期望交期 -->
            <div v-else-if="item.prop === 'expectDeliveryDate' && tabsType == 2">
              {{ utils.cutDate(row.expectDeliveryDate) }}
            </div>
            <!-- 产品明细 是否加急 -->
            <div v-else-if="item.prop === 'urgent' && tabsType == 2">
              {{ row.urgent == 1 ? '是' : '否' }}
            </div>
            <!-- 产品明细 要求加工数量 -->
            <div v-else-if="item.prop === 'processNum' && tabsType == 2">
              {{ row.processNum + row.sampleNum }}
            </div>
            <!-- 其他 -->
            <span v-else>{{ row[item.prop] || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 订单明细操作 -->
            <div v-if="tabsType == 1">
              <el-button v-show="scope.row.resultStatus === 1" type="text" size="medium" @click="edit(scope.row)">
                编辑
              </el-button>
              <el-button v-show="scope.row.status === 1" type="text" size="medium" @click="cancel(scope.row)">
                作废
              </el-button>
            </div>
            <!-- 产品明细操作 -->
            <div v-if="tabsType == 2">
              <el-button type="text" size="medium" @click="printXM(scope.row)">
                打印箱唛
              </el-button>
            </div>
          </template>
          <template></template>
        </el-table-column>
      </ErpTable>
    </div>
    <FinishPorcess @FinishPorcess="initProduct" ref="finishPorcess"></FinishPorcess>
    <!-- crm验货 -->
    <crm-modal @examineCargo="initProduct" ref="crmModal"></crm-modal>

    <!-- 导出任务创建成功提示 -->
    <SuccessModel :visible.sync="downloadVisible" />

    <!-- 费用调整弹框 -->
    <JustmentExpensesDialog
      v-model="justmentExpensesVisible"
      :processProductFeeDTOS="processProductFeeDTOS"
      :getTotalFee="getTotalFee"
      @refresh="initProduct"
    />
  </div>
</template>
<script>
  import { InboundSheet, OutProcessing, OutboundSheetInteractor } from '@/core'
  import Dicts from './dict/index'
  import ErpDraggable from 'vuedraggable'
  import FinishPorcess from './components/finish-process'
  import crmModal from '@/views/purchase/cargoManage/components/crmModal'
  import SuccessModel from '@/components/tableImport/successModel'
  import JustmentExpensesDialog from './components/justment-expenses-dialog'
  import CustomCache from '@/utils/custom-cache'
  import { mapGetters } from 'vuex'
  import { hasRight } from '@/utils/permissionBtn'
  import * as Dayjs from 'dayjs'
  import { add, sub, mult, encode } from 'kits'
  import { Loading } from 'element-ui'
  function _initCreateDate(num) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * num)
    return [
      Dayjs(start.getTime()).format('YYYY-MM-DD'),
      Dayjs(end.getTime()).format('YYYY-MM-DD'),
    ]
  }
  export default {
    name: 'outsourceProcess',
    components: {
      ErpDraggable,
      FinishPorcess,
      crmModal,
      SuccessModel,
      JustmentExpensesDialog,
    },
    data() {
      return {
        tabsType: 1,
        shipStatus: '标记已出运',
        timeOrder: '按【创建时间】倒序',
        orderByType: 1,
        // 订单明细搜索条件
        filterOrderForm: {
          salesOrderCode: '', // 销售单号
          processOrderCode: '', // 加工单号
          businessUserId: '', // 客户代表
          skuId: '', // 产品编码
          status: '', // 状态
          shippingMethodType: '', //运输方式
          createStartTime: '', // 开始时间
          createEndTime: '', // 结束时间
          createId: '', // 创建人
          createdTime: _initCreateDate(6),
        },
        // 产品明细搜索条件
        filterProductForm: {
          salesOrderCode: '', // 销售单号
          processOrderCode: '', // 加工单号
          returnProgressList: [], // 退料进度，多选
          supplierId: '', // 供应商，单选
          businessUserId: '', // 客户代表
          skuId: '', // 产品编码
          skuName: '', // 产品名称
          matterStatus: '', // 领料进度
          inspectionStatus: '', // 验货状态
          shippingMethodType: '', //运输方式
          deliveryStartTime: '', // 期望交期开始时间
          deliveryEndTime: '', // 期望交期结束时间
          createStartTime: '', // 创建开始时间
          createEndTime: '', // 创建结束时间
          createId: '', // 创建人
          createdTime: _initCreateDate(6),
          completeStartTime: '', // 完成时间开始时间
          completeEndTime: '', // 完成时间结束时间
        },
        orderFormSearch: [
          {
            type: 'text',
            name: 'salesOrderCode',
            prop: 'salesOrderCode',
            label: '销售单号',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'text',
            name: 'processOrderCode',
            prop: 'processOrderCode',
            label: '加工单号',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'daterange',
            name: 'createdTime',
            prop: 'createdTime',
            label: '创建日期',
            placeholder: '请选择',
          },
          {
            type: 'userselect',
            name: 'businessUserId',
            prop: 'businessUserId',
            label: '客户代表',
            placeholder: '请选择',
            chooseUser: this.chooseBusiness,
          },
          {
            type: 'text',
            name: 'skuId',
            prop: 'skuId',
            label: '产品编码',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'select',
            name: 'status',
            prop: 'status',
            label: '状态',
            placeholder: '请选择',
            optList: [
              { label: '正常', value: 1 },
              { label: '已作废', value: 2 },
            ],
          },
          {
            type: 'userselect',
            name: 'createId',
            prop: 'createId',
            label: '创建人',
            placeholder: '请选择',
            chooseUser: this.chooseCreate,
          },
        ],
        productFormSearch: [
          {
            type: 'text',
            name: 'salesOrderCode',
            prop: 'salesOrderCode',
            label: '销售单号',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'text',
            name: 'processOrderCode',
            prop: 'processOrderCode',
            label: '加工单号',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'daterange',
            name: 'createdTime',
            prop: 'createdTime',
            label: '创建日期',
            placeholder: '请选择',
          },
          {
            type: 'select',
            name: 'shipFlag',
            prop: 'shipFlag',
            label: '出运状态',
            placeholder: '请选择',
            optList: [
              { label: '未出运', value: 0 },
              { label: '已出运', value: 1 },
            ],
          },
          {
            type: 'select',
            name: 'returnProgressList',
            prop: 'returnProgressList',
            label: '退料进度',
            placeholder: '请选择',
            multiple: true,
            optList: [
              { label: '未退料', value: 1 },
              { label: '部分退料', value: 2 },
              { label: '全部退料', value: 3 },
            ],
          },
          {
            type: 'select',
            name: 'supplierId',
            prop: 'supplierId',
            label: '供应商',
            placeholder: '请选择',
            optList: [],
          },
          {
            type: 'userselect',
            name: 'businessUserId',
            prop: 'businessUserId',
            label: '客户代表',
            placeholder: '请选择',
            chooseUser: this.chooseBusiness,
          },
          {
            type: 'text',
            name: 'skuId',
            prop: 'skuId',
            label: '产品编码',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'text',
            name: 'skuName',
            prop: 'skuName',
            label: '产品名称',
            placeholder: '请输入',
            maxlength: 30,
          },
          {
            type: 'select',
            name: 'matterStatus',
            prop: 'matterStatus',
            label: '领料进度',
            placeholder: '请选择',
            optList: [
              { label: '未领料', value: 1 },
              { label: '部分领料', value: 2 },
              { label: '全部领料', value: 3 },
            ],
          },
          {
            type: 'select',
            name: 'inspectionStatus',
            prop: 'inspectionStatus',
            label: '验货状态',
            placeholder: '请选择',
            optList: [
              { label: '未安排验货', value: 1 },
              { label: '待验货', value: 2 },
              { label: '验货完成', value: 3 },
              { label: '验货中', value: 4 },
              { label: '验货不合格', value: 5 },
            ],
          },
          {
            type: 'select',
            name: 'printWay',
            prop: 'printWay',
            label: '印刷方式',
            placeholder: '请选择',
            optList: Dicts.printingWaySelect,
          },
          {
            type: 'select',
            name: 'shippingMethodType',
            prop: 'shippingMethodType',
            label: '运输类型',
            placeholder: '请选择',
            optList: [
              { label: '空运', value: 1 },
              { label: '海运', value: 2 },
              { label: '快递', value: 3 },
            ],
          },
          {
            type: 'daterange',
            name: 'deliveryTime',
            prop: 'deliveryTime',
            label: '期望交期',
            placeholder: '请选择',
          },
          {
            type: 'userselect',
            name: 'createId',
            prop: 'createId',
            label: '创建人',
            placeholder: '请选择',
            chooseUser: this.chooseCreate,
          },

          {
            type: 'daterange',
            name: 'completeTime',
            prop: 'completeTime',
            label: '完工时间',
            placeholder: '请选择',
            labelWidth: '95px',
          },
          {
            type: 'daterange',
            name: 'productionTime',
            prop: 'productionTime',
            label: '预计生产时间',
            placeholder: '请选择',
            labelWidth: '120px',
          },
        ],
        searchFormConfig: {
          labelPosition: 'right',
          defaultExpand: false, //默认展开
          formItemList: null,
          operate: [
            {
              type: 'primary',
              name: '搜索',
              handleClick: this.onButtonSearch?.bind(this),
            },
            {
              type: 'plain',
              name: '重置',
              handleClick: this.onReset?.bind(this),
            },
          ],
        },
        shippingMethodTypeOptions: [
          { label: '空运', value: 1 },
          { label: '海运', value: 2 },
          { label: '快递', value: 3 },
        ],

        searchKey: Math.random(),
        tableKey: Math.random() + 100,
        orderColumns: Dicts.orderColumns,
        productColumns: Dicts.productColumns,
        statusList: Dicts.statusList,
        pageNo: 1,
        pageLe: 10,
        total: 0,
        tableData: [], // 表格数据
        startWork: false, // 开始加工弹窗
        status: null, // 状态
        selection: [],
        userCustomizeColumnId: '', // 自定义列id
        isShowTab: false,
        hasTransRight: false, // 修改运输类型权限
        hasProductionTimeRight: false, // 修改预计生产时间权限
        downloadVisible: false,
        justmentExpensesVisible: false, // 费用调整弹框显隐
        processProductFeeDTOS: [], // 费用调整弹框，列表数据
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      dragOptions() {
        return {
          animation: 600,
          group: 'description',
        }
      },
      // 获取搜索项
      formSearch() {
        return this.tabsType == 1
          ? this.orderFormSearch
          : this.productFormSearch
      },
      finallyColumns() {
        let columns =
          this.tabsType == 1 ? this.orderColumns : this.productColumns
        return columns.filter(
          (item) => item.isShow === true && item.prop !== 'processOrderCode'
        )
      },
      customList() {
        return this.tabsType == 1 ? this.orderColumns : this.productColumns
      },
      menuType() {
        return this.tabsType == 1 ? 18 : 19
      },
    },
    watch: {
      // 切换tab 切换搜索
      tabsType: {
        handler() {
          this.searchKey = Math.random()
          this.searchFormConfig.formItemList = this.formSearch
          this.shipStatus = '标记已出运'
          this.pageNo = 1
          this.pageLe = 10
          this.getColumsData()
        },
        immediate: true,
      },
    },
    activated() {
      this.searchKey = Math.random()
      this.tableKey = Math.random() + 1100
      this.hasTransRight = hasRight('Change:TransType')
      this.hasProductionTimeRight = hasRight('Change:ProductionTime')
      this.setTabAndSort()
      this.changeTimeOrder(this.timeOrder)
    },
    created() {
      this.getFactorySupplierListPage()
    },

    filters: {
      //运输类型
      shippingMethodTypeName(n) {
        return Dicts.shippingMethodTypeName(n)
      },
      // 状态
      statusTypeName(n) {
        return Dicts.statusTypeName(n)
      },
      // 加工状态
      pickingStatusTypeName(n) {
        return Dicts.pickingStatusTypeName(n)
      },
      // 验货状态
      inspectionStatusTypeName(n) {
        return Dicts.inspectionStatusTypeName(n)
      },
      // 领料进度
      matterStatusTypeName(n) {
        return Dicts.matterStatusTypeName(n)
      },
      // 出运状态
      shipFlagTypeName(n) {
        return Dicts.shipFlagTypeName(n)
      },
    },
    methods: {
      // 获取tab和排序缓存数据
      setTabAndSort() {
        // 每次进入页面都要记住上次的选择的tab 和 排序规则
        let tabsType = 1
        if (localStorage.getItem('OUTPORCESS_TABSTYPE')) {
          tabsType = localStorage.getItem('OUTPORCESS_TABSTYPE') * 1
        }
        this.tabsType = tabsType
        if (this.tabsType == 1) {
          if (
            localStorage.getItem(`OUTPORCESS_1`) &&
            localStorage.getItem(`OUTPORCESS_1`) != 'null'
          ) {
            this.timeOrder = localStorage.getItem(`OUTPORCESS_1`)
          } else {
            this.timeOrder = '按【创建时间】倒序'
          }
        }
        if (this.tabsType == 2) {
          if (
            localStorage.getItem(`OUTPORCESS_2`) &&
            localStorage.getItem(`OUTPORCESS_2`) != 'null'
          ) {
            this.timeOrder = localStorage.getItem(`OUTPORCESS_2`)
          } else {
            this.timeOrder = '按【期望交期】正序'
          }
        }
        this.isShowTab = true
      },
      // 订单明细产品明细切换
      tabsTypeChange(val) {
        // 查找本地是否有缓存，无缓存则赋值默认
        if (this.tabsType == 1) {
          this.timeOrder = localStorage.getItem('OUTPORCESS_1')
            ? localStorage.getItem('OUTPORCESS_1')
            : '按【创建时间】倒序'
        } else {
          this.timeOrder = localStorage.getItem('OUTPORCESS_2')
            ? localStorage.getItem('OUTPORCESS_2')
            : '按【期望交期】正序'
        }
        this.userCustomizeColumnId = ''
        this.tableData = []
        this.changeTimeOrder(this.timeOrder)
      },

      // 初始化 订单明细
      async initOrder() {
        const _obj = this.handleSearchConfig(this.filterOrderForm)
        const params = {
          ..._obj,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          orderByType: this.orderByType,
        }
        const res = await OutProcessing.listPageByOrder(params)

        if (res && res.code === '000000') {
          this.tableData = res.data.data
          this.total = res.data.total
        }
      },

      // 初始化 产品明细
      async initProduct() {
        const _obj = this.handleSearchConfig(this.filterProductForm)
        const params = {
          ..._obj,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          orderByType: this.orderByType,
          status: this.status,
        }
        const res = await OutProcessing.listPageByOrderProduct(params)

        if (res && res.code === '000000') {
          this.tableData = res.data.data?.map((item) => {
            return {
              ...item,
              shippingMethodType: Number(item.shippingMethodType), // 运输类型渲染特殊处理
              // 如果印刷方式为无需印刷，效果图取productImage，取不到就取imgMain；否则直接取productImage
              img: item.printWay === '无需印刷' ? item.productImage || item.imgMain : item.productImage,
            }
          })
          this.total = res.data.total
        }
      },

      // 处理搜索参数
      handleSearchConfig(_obj) {
        if (_obj.createdTime) {
          _obj.createStartTime = _obj.createdTime[0]
          _obj.createEndTime = _obj.createdTime[1]
        } else {
          _obj.createStartTime = ''
          _obj.createEndTime = ''
        }
        if (_obj.deliveryTime) {
          _obj.deliveryStartTime = _obj.deliveryTime[0]
          _obj.deliveryEndTime = _obj.deliveryTime[1]
        } else {
          _obj.deliveryStartTime = ''
          _obj.deliveryEndTime = ''
        }
        if (_obj.completeTime) {
          _obj.completeStartTime = _obj.completeTime[0]
          _obj.completeEndTime = _obj.completeTime[1]
        } else {
          _obj.completeStartTime = ''
          _obj.completeEndTime = ''
        }
        if (_obj.productionTime) {
          _obj.productionStartTime = _obj.productionTime[0]
          _obj.productionEndTime = _obj.productionTime[1]
        } else {
          _obj.productionStartTime = ''
          _obj.productionEndTime = ''
        }

        return _obj
      },

      // 搜索
      onButtonSearch() {
        this.pageNo = 1
        this.query()
      },

      // 重置
      onReset() {
        this.pageNo = 1
        this.pageLe = 10
        this.status = null
        this.filterOrderForm = this.$options.data().filterOrderForm
        this.filterProductForm = this.$options.data().filterProductForm
        this.$refs.erpsearch.resetUserSelect()
        this.query()
      },

      query() {
        this.tabsType == 1 ? this.initOrder() : this.initProduct()
      },

      // 多选 表格数据
      setSelectRows(val) {
        this.selection = val
      },

      // 标记已出运
      async changeShipStatus(val) {
        if (this.selection?.length === 0) {
          return this.$message.warning('请选择加工单')
        }
        let st = this.selection.every((it) => it.operateProcessOrder == 1)
        if (!st) {
          this.$message.warning('没有操作权限！')
          return
        }

        let _ids = []
        this.selection.forEach((item) => _ids.push(item.processProductId))
        const params = {
          processProductIdList: _ids,
          shipFlag: val === '标记未出运' ? 0 : 1,
        }
        const res = await OutProcessing.productUpdateShipFlag(params)

        if (res && res.code === '000000') {
          this.query()
          this.shipStatus = val
          this.$message.success('操作成功')
        }
      },

      // 时间排序
      changeTimeOrder(n) {
        switch (n) {
          case '按【创建时间】正序':
            this.orderByType = 1
            break
          case '按【创建时间】倒序':
            this.orderByType = 2
            break
          case '按【期望交期】正序':
            this.orderByType = 3
            break
          case '按【期望交期】倒序':
            this.orderByType = 4
            break
          case '按【完工时间】正序':
            this.orderByType = 5
            break
          case '按【完工时间】倒序':
            this.orderByType = 6
            break
        }
        this.timeOrder = n
        this.query()
        this.setCache()
      },
      setCache() {
        localStorage.setItem('OUTPORCESS_TABSTYPE', this.tabsType)
        localStorage.setItem(`OUTPORCESS_${this.tabsType}`, this.timeOrder)
      },

      // 加工单跳转详情
      detail(row) {
        this.$router.push({
          path: '/purchase/outsourceProcess/outsource-detail',
          query: {
            id: row.processOrderId,
            code: row.processOrderCode,
          },
        })
      },

      // 销售单跳转详情
      orderDetail(row) {
        let routeData = this.$router.resolve({
          path: '/order/orderList/orderDetail',
          query: {
            orderCode: row.salesOrderCode,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },

      // 打印箱唛
      async printXM(row) {
        if (row.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        const res = await OutProcessing.processPrintOrderMarks({
          orderId: row.salesOrderId,
          orderProductUniqueId: row.orderProductUniqueId.split(','),
          printType: 3,
          checkFlag: 0,
          skuIds: row.skuId.split(','),
        })

        if (res && res.code === '000000') {
          if (res.data?.marksShowVOS?.length) {

            window.open(
              window.location.origin +
              `#/print/orderShipMark?QRCode=${true}&rendImgDisplayed=${false}&content=${encode(res.data.marksShowVOS)}&type=1`,
              '_blank'
            )
          } else {
            this.$message.warning('箱唛内容为空！')
          }
        }
      },

      // 新增
      addProcess(type) {
        // type:order:订单明细   product:产品明细
        this.$router.push({
          path: '/purchase/outsourceProcess/outsource-add',
          query: {
            type,
          },
        })
      },

      // 编辑
      edit(row) {
        if (row.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        this.$router.push(
          `/purchase/outsourceProcess/outsource-add?id=${row.processOrderId}`
        )
      },

      // 作废
      cancel(row) {
        if (row.operateProcessOrder != 1) {
          this.$message.warning('没有操作权限！')
          return
        }
        if (row.resultStatus !== 1) {
          this.$message.warning(
            '该加工单下产品已经开始加工，请联系加工厂进行异常完工'
          )
          return
        }
        this.$confirm('此操作将作废该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const res = await OutProcessing.updateDelete(row.processOrderId)

          if (res && res.code === '000000') {
            this.initOrder()
            this.$message({
              type: 'success',
              message: '操作成功',
            })
          }
        })
      },

      // 打印加工单
      printProcess(type) {
        if (this.selection?.length === 0) {
          return this.$message.warning('请选择加工单')
        }

        let st = this.selection.every((it) => it.operateProcessOrder == 1)
        if (!st) {
          this.$message.warning('没有操作权限！')
          return
        }

        let _ids = []
        this.selection.forEach((item) =>
          _ids.push(type == 1 ? item.processOrderId : item.processProductId)
        )
        const routeData = this.$router.resolve({
          path: `/print/processSheet?ids=${_ids.join(',')}&type=${type}`,
        })
        window.open(routeData.href, '_blank')
      },

      // 开始加工
      setStartWork() {
        if (this.selection?.length === 0) {
          return this.$message.warning('请选择产品')
        }
        let st = this.selection.every((it) => it.operateProcessOrder == 1)
        if (!st) {
          this.$message.warning('没有操作权限！')
          return
        }
        const sheets = new Set(this.selection.map((i) => i.processOrderCode))
        if (sheets.size > 1) {
          return this.$message.warning('请选择同一个加工单下的产品')
        }
        if (!this.selection.every((item) => item.pickingStatus === 1)) {
          return this.$message.warning('请确认所选产品均为未加工状态')
        }

        this.$confirm('确认开始加工?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          let _ids = []
          this.selection.forEach((item) => _ids.push(item.processProductId))
          const res = await OutProcessing.updateShipFlag(_ids)
          if (res && res.code === '000000') {
            this.initProduct()
            this.$message.success('操作成功')
          }
        })
      },

      // 加工完成
      setFinishWork() {
        if (this.selection?.length === 0) {
          return this.$message.warning('请选择产品')
        }
        let st = this.selection.every((it) => it.operateProcessOrder == 1)
        if (!st) {
          this.$message.warning('没有操作权限！')
          return
        }
        const sheets = new Set(this.selection.map((i) => i.processOrderCode))
        if (sheets.size > 1) {
          return this.$message.warning('请选择同一个加工单下的产品')
        }
        for (let i = 0; i < this.selection.length; i++) {
          if (![1, 2].includes(this.selection[i].pickingStatus)) {
            return this.$message.warning(
              `${this.selection[i].processOrderCode}不是未加工或加工中状态，请确认`
            )
          }
          if (![3].includes(this.selection[i].matterStatus)) {
            return this.$message.warning(
              `${this.selection[i].processOrderCode}领料数量不足，请确认领料数量`
            )
          }
        }
        this.$refs.finishPorcess.init(
          JSON.parse(JSON.stringify(this.selection))
        )
      },

      // 选择客户代表
      chooseBusiness(val) {
        if (this.tabsType === 1) {
          this.filterOrderForm.businessUserId = val.userId
        } else {
          this.filterProductForm.businessUserId = val.userId
        }
      },

      // 选择创建人
      chooseCreate(val) {
        if (this.tabsType === 1) {
          this.filterOrderForm.createId = val.userId
        } else {
          this.filterProductForm.createId = val.userId
        }
      },

      // 退料入库 type === 2 加工入库 type === 1 type ==0 领料出库
      outbound(type) {
        if (this.selection?.length === 0) {
          return this.$message.warning('请选择产品')
        }
        let st = this.selection.every((it) => it.operateProcessOrder == 1)
        if (!st) {
          this.$message.warning('没有操作权限！')
          return
        }
        const sheets = new Set(this.selection.map((i) => i.processOrderCode))
        if (sheets.size > 1) {
          return this.$message.warning('请选择同一个加工单下的产品')
        }
        // 领料出库判断
        let selectionSatus_0 = this.selection.every(
          (it) => it.pickingStatus === 3 || it.pickingStatus === 4
        )
        if (type === 0 && selectionSatus_0) {
          return this.$message.warning('加工完成不能进行领料出库')
        }

        // 加工入库判断
        if (type === 1 && !selectionSatus_0) {
          return this.$message.warning('加工完成后才能进行加工入库')
        }

        // 退料入库 判断
        let selectionSatus_2 = this.selection.every((it) => {
          return (
            it.processNum > 0 &&
            (it.pickingStatus === 2 ||
              it.pickingStatus === 1 ||
              it.pickingStatus === 5)
          )
        })

        if (type === 2 && !selectionSatus_2) {
          return this.$message.warning(
            '只能选择用料数量大于0且未加工，加工中和已作废的单据'
          )
        }

        const type2path = {
          0: '/purchase/outsourceProcess/outbound-apply',
          1: '/purchase/outsourceProcess/inbound-apply',
          2: '/purchase/outsourceProcess/inbound-apply',
        }
        const processProductIds = this.selection
          .map((i) => i.processProductId)
          .join(',')
        const query = {
          processProductIds,
        }
        if (type == 1 || type == 2) {
          query.type = type == 1 ? 'JIA' : 'TUI'
        }
        // 退料入库
        if (type == 0) {
          OutboundSheetInteractor.pickingProductQuery({
            processProductIds,
          }).then((res) => {
            if (res && res.code === '000000') {
              this.$router.push({
                path: type2path[type],
                query,
              })
            }
          })
        }

        // 加工入库额外判断
        if (type == 1) {
          InboundSheet.warehouseInApplyQuery(processProductIds).then((res) => {
            if (res && res.code === '000000') {
              this.$router.push({
                path: type2path[type],
                query,
              })
            }
          })
        }
        if (type == 2) {
          this.$router.push({
            path: type2path[type],
            query,
          })
        }
      },

      // 验货通知
      async cargo() {
        if (this.selection?.length === 0) {
          return this.$message.warning('请选择产品')
        }
        let st = this.selection.every((it) => it.operateProcessOrder == 1)
        if (!st) {
          this.$message.warning('没有操作权限！')
          return
        }
        const sheets = new Set(this.selection.map((i) => i.processOrderCode))
        const status = this.selection.every(
          (it) => it.pickingStatus === 3 || it.pickingStatus === 4
        )

        if (sheets.size > 1 || !status) {
          return this.$message.warning('请选择同一个加工单下的【已完工】产品')
        }
        // 加工单回显验货信息
        let _ids = []
        this.selection.forEach((item) => _ids.push(item.processProductId))
        const res = await OutProcessing.getInspectionOrderProcess(_ids)

        if (res && res.code === '000000') {
          const data = res.data
          const config = {
            title: '新增验货单',
            type: 'OutProcessing',
            supplierName: data.supplierName, // 供应商
            inspector: data.inspector, // QC
            inspectorId: data.inspectorId,
            processOrderCode: data.processOrderCode, // 加工单号
            list: data.inspectionProductEditVOList, // 销售订单信息
          }
          this.$refs.crmModal.setCrmData(config)
        }
      },

      // 获取自定义保存
      getColumsData() {
        CustomCache.getColumns(this.menuType, this.userInfo.userId).then(
          (res) => {
            if (res && res.columns) {
              this.userCustomizeColumnId = res.userCustomizeColumnId
              if (this.tabsType == 1) {
                this.orderColumns = res.columns
              } else {
                this.productColumns = res.columns
              }
              this.tableKey = Math.random() + 100 // 在获取到自定义列后，刷新列表
            }
          }
        )
      },

      // 保存自定义显示列
      operateSave() {
        let customizeData = JSON.stringify(
          this.tabsType == 1 ? this.orderColumns : this.productColumns
        )
        let params = {
          userCustomizeColumnId: this.userCustomizeColumnId,
          menuType: this.menuType,
          userId: this.userInfo.userId,
          customizeData,
        }
        CustomCache.setColumns(params).then((res) => {
          if (res.code === '000000') {
            this.userCustomizeColumnId = res.data.userCustomizeColumnId
            return this.$baseMessage(
              '操作成功',
              'success',
              false,
              'erp-hey-message-success'
            )
          }
        })
      },

      // 修改运输类型
      async selectTransType(val, id) {
        const res = await OutProcessing.updateShippingMethodType(id, val)

        if (res && res.code === '000000') {
          this.$message.success('修改成功')
        }
      },

      // 修改预计生产时间
      async changeProductionTime(val, id) {
        const res = await OutProcessing.updateProductionTime(id, val)

        if (res && res.code === '000000') {
          this.$message.success('修改成功')
        }
      },

      // 状态标签转义
      transStatusType(n) {
        if ([1].includes(n)) {
          return 'success'
        }
        if ([2].includes(n)) {
          return 'danger'
        }
      },

      // 加工状态标签转义
      transPickingStatusType(n) {
        if ([1].includes(n)) {
          return 'info'
        }
        if ([2].includes(n)) {
          return 'warning'
        }
        if ([3].includes(n)) {
          return 'success'
        }
        if ([4, 5].includes(n)) {
          return 'danger'
        }
      },

      // 加急工单 标红
      urgentClassName({ row }) {
        if (row.urgent === 1) {
          return 'red-row'
        }
      },

      // 跳转到验货管理
      goPage(row) {
        const routeData = this.$router.resolve({
          path: '/purchase/cargoManage/cargoManage',
          query: {
            processOrderCode: row.processOrderCode,
            sku: row.skuId,
            orderProductUniqueId: row.orderProductUniqueId,
          },
        })

        window.open(routeData.href, '_blank')
      },

      //导出
      exportData() {
        const _obj = this.handleSearchConfig(this.filterProductForm)
        const params = {
          ..._obj,
          pageNo: this.pageNo,
          pageLe: this.pageLe,
          orderByType: this.orderByType,
          status: this.status,
        }
        OutProcessing.exportProuctsApi(params).then((res) => {
          if (res && res.code === '000000') {
            this.downloadVisible = true
          }
        })
      },
      // 获取供应商下拉
      async getFactorySupplierListPage() {
        try {
          const params = {
            tenantId: this.userInfo.tenantId,
            pageLe: 500
          }
          const { code, data } = await OutProcessing.factorySupplierListPageApi(params)
          if(code === '000000') {
            if(Array.isArray(data?.data)) {
              // 查询项-供应商下拉
              const optList = data.data.map(n => ({ label: n.supplierName, value: n.supplierId }))
              this.productFormSearch = this.productFormSearch.map(n => {
                if(n.prop === 'supplierId') n.optList = optList
                return n
              })
            }
          }
        } catch (e) {
          console.log(e)
        }
      },

      // 费用调整
      async handleadJustmentExpenses() {
        const { selection } = this
        if (selection?.length === 0) {
          return this.$message.warning('请选择产品')
        }
        if (selection?.length > 100) {
          return this.$message.warning('最多选择100条')
        }
        const isComplete = selection.every((n) =>
          [3, 4].includes(+n.pickingStatus)
        ) // 3=已完工 4=异常完工
        if (!isComplete) {
          return this.$message.warning(
            '所选择的产品必须是必须全部是已完工或异常完工'
          )
        }
        const isSettlement = selection.some((n) => [2, 3, 4].includes(+n.settlementStatus))
        if (isSettlement) {
          return this.$message.warning(
            '所选择的加工单必须全部未进入结算状态的销售订单'
          )
        }
        const st = selection.every((n) => n.operateProcessOrder == 1)
        if (!st) {
          return this.$message.warning('没有操作权限！')
        }
        const loading = Loading.service({
          fullscreen: true,
          background: 'rgba(0, 0, 0, 0.4)',
          text: 'Loading',
        })
        const checkList = await this.checkAdjustDay(selection)
        if (checkList === 'err') return loading.close()
        if (checkList?.length) {
          const names = checkList.map(n => n.trim()).join('，')
          const msg = `当前日期超出所选择产品对应加工单供应商：${names}的加工费用调整期限，不允许调整`
          this.$alert(msg, '提示', {
            confirmButtonText: '确认',
            customClass: 'justment-expenses-tip',
          })
          loading.close()
        } else {
          const ids = selection.map((n) => n.processProductId)
          const data = await this.getProcessProductListFee(ids)
          this.processProductFeeDTOS = data
          this.justmentExpensesVisible = true
          loading.close()
        }
      },
      // 校验加工单日期是否合格
      async checkAdjustDay(selection) {
        try {
          const { code, data } = await OutProcessing.checkAdjustDayApi(selection)
          if (code === '000000') {
            return Promise.resolve(data)
          } else {
            return Promise.resolve('err')
          }
        } catch (e) {
          console.log(e)
        }
      },
      // 根据选中的id集合，获取到费用调整的list
      async getProcessProductListFee(ids) {
        try {
          const { code, data } = await OutProcessing.processProductListFeeApi(ids)
          if (code === '000000' && Array.isArray(data)) {
            for (const row of data) {
              const { feeUnitPrice, editionFee, otherFee, discountAmount } = row
              const totalFee = this.getTotalFee(row)
              const beforeData = {
                feeUnitPrice,
                editionFee,
                otherFee,
                discountAmount,
                totalFee,
              }
              // 初始化时，合计费用和上一次的合计费用相同，本次调整金额为0，并且提供表格展开数据expandData
              Object.assign(row, {
                totalFee,
                adjustFee: 0,
                expandData: [beforeData],
              })
            }
            return Promise.resolve(data)
          }
        } catch (e) {
          console.log(e)
        }
      },
      /**
       * 计算制程损耗
       * 合理损耗范围 = (订单数量 + 留样数量) x 损耗率，结果抹除多余小数位、保留整数
       */
      getProcessLoseNum(row) {
        const { processNum, sampleNum, loseRatio, processLoseNum } = row
        // 合理损耗范围
        let num = mult(mult(add(processNum, sampleNum), loseRatio), '0.01')
        num = Math.floor(num)
        // 合理损耗范围 > 制程损耗 时，合理损耗范围 = 制程损耗
        if (num > Number(processLoseNum)) {
          num = processLoseNum
        }
        return num
      },
      // 计算合计费用
      getTotalFee(row) {
        const {
          qualityNum,
          feeUnitPrice,
          editionFee,
          otherFee,
          discountAmount,
        } = row
        const num = this.getProcessLoseNum(row) // 制程损耗
        // 合计费用 = (合格数量 + 制程损耗) * 加工费单价 + 版费 + 其他费用 - 折扣金额
        const totalFee = sub(
          add(
            add(mult(add(qualityNum, num), feeUnitPrice), editionFee),
            otherFee
          ),
          discountAmount
        )
        return totalFee
      },
    },
  }
</script>
<style lang="scss" scoped>
  .inspectionStatus-sty {
    position: relative;

    i {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .btn-box {
    display: flex;
    justify-content: space-between;

    ::v-deep {
      .el-dropdown {
        margin-left: 10px;

        .el-button-group {
          .el-button:first-child {
            min-width: 80px;
            height: 32px;
          }
        }
      }
    }

    .right-btn {
      ::v-deep {
        .el-dropdown {
          margin-left: 10px;

          .el-button-group {
            .el-button:first-child {
              min-width: 140px;
              height: 32px;
            }
          }
        }
      }
    }

    ::v-deep {
      .el-tooltip {
        font-size: 12px;
      }
    }
  }

  ::v-deep {
    .el-table .red-row {
      background: #ffacac;
    }

    .createTime .cell {
      width: auto !important;
    }
  }
</style>
<style lang="scss">
  .el-message-box.justment-expenses-tip {
    .el-message-box__message p {
      word-break: break-all;
      text-align: justify;
      max-height: 450px;
      overflow: auto;
    }
  }
</style>
