<!--
  功能：出运计划
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月15日 16:45:05
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-container" v-if="$route.meta.showParent">
    <!-- 搜索 -->
    <ErpSearch
      ref="searchForm"
      :value="filterForm"
      :searchFormConfig="searchFormConfig"
      @enter-search="onButtonSearch"
    />
    <el-table
      border
      style="margin-bottom: -5px"
      :data="tableData"
      :height="tableHeight"
      :cell-style="{ 'text-align': 'center' }"
      :header-cell-style="{ 'text-align': 'center' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="50"
        fixed="left"
      ></el-table-column>
      <el-table-column label="计划单号" width="120" fixed="left">
        <template slot-scope="scope">
          <span class="page-link text-left" @click="showPlanDetail(scope.row)">
            {{ scope.row.planNumber }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="订单编号" prop="piNumber" width="160">
        <template slot-scope="scope">
          <span class="page-link text-left" @click="showOrderDetail(scope.row)">
            {{ scope.row.piNumber }}

            <ErpMouseoverTip v-if="scope.row.salesOrderException === 1">
              <div>
                <h3>销售订单异常</h3>
                <p class="info-text">
                  异常原因:{{ scope.row.salesOrderExceptionReason }}
                </p>
              </div>
            </ErpMouseoverTip>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="客户代表"
        prop="businessName"
        width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="运输方式"
        prop="shippingMethodList"
        show-overflow-tooltip
        width="120"
      ></el-table-column>
      <el-table-column
        label="国家"
        prop="calculateCaseNum"
        width="120"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.country ? scope.row.country : '--' }}
        </template>
      </el-table-column>
      <el-table-column
        label="目的地"
        prop="destination"
        width="200px"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="出运产品"
        prop="shipmentProduct"
        width="180px"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-popover
            placement="bottom"
            width="1000"
            trigger="click"
            @show="getPros(scope.row)"
            @hide="onProHide"
          >
            <span class="page-link text-left" slot="reference">
              {{ scope.row.skuList }}
            </span>

            <el-table
              border
              stripe
              :data="products"
              max-height="350"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
            >
              <el-table-column label="产品图片">
                <template slot-scope="{ row }">
                  <div>
                    <erp-image :imgs="row.productImg"></erp-image>
                    <popover-ship
                      :showTable="false"
                      :itemTitle="
                        row.mainFlag == 1 ? '主' : row.mainFlag == 0 ? '子' : ''
                      "
                    ></popover-ship>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="产品编码" width="100">
                <template slot-scope="scope">
                  <span
                    class="page-link"
                    @click="showProDetail(scope.row.productId)"
                  >
                    {{ scope.row.sku }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="中文品名/英文品名"
                prop=""
                width="150px"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.productCn }}/{{ scope.row.productEn }}
                </template>
              </el-table-column>
              <el-table-column
                label="订单数量"
                prop="totalNum"
              ></el-table-column>
              <el-table-column
                label="剩余出运数量"
                prop="unShippedNum"
                width="120px"
              ></el-table-column>
              <!-- <el-table-column label="总箱数" prop="caseNum"></el-table-column>
              <el-table-column
                label="总毛重"
                prop="grossWeight"
              ></el-table-column>
              <el-table-column label="总体积" prop="volume"></el-table-column> -->
              <el-table-column
                label="客户交期"
                prop="customerDeliveryDateList"
                width="150"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.customerDeliveryDateList }}
                </template>
              </el-table-column>
            </el-table>
          </el-popover>
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="总件数"
        prop="totalNum"
        width="120"
      ></el-table-column>
      <el-table-column
        label="总毛重"
        prop="totalGrossWeight"
        width="120"
      ></el-table-column>
      <el-table-column
        label="总体积"
        prop="totalVolume"
        width="120"
      ></el-table-column> -->
      <el-table-column
        label="总数量"
        prop="totalNum"
        width="120"
      ></el-table-column> 
      <el-table-column
        label="剩余出运数量"
        prop="totalUnShippedNum"
        width="120"
      ></el-table-column> 
      <el-table-column label="计划装运日期" prop="planShippingDateList" width="120"  show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.planShippingDateList || '--' }}
        </template>
      </el-table-column>
      <el-table-column label="客户交期" prop="customerDeliveryDateList" width="120"  show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.customerDeliveryDateList || '--' }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="交货日期" prop="lastDate" width="120">
        <template slot-scope="scope">
          {{ scope.row.purchaseDeliveryDate | timeFilter }}
        </template>
      </el-table-column> -->
      <el-table-column
        label="备注"
        prop="remarks"
        width="160"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            v-if="!scope.row.remarkEdit"
            @dblclick="toggleRemark(scope.row)"
          >
            {{ scope.row.remarks ? scope.row.remarks : '--' }}
          </span>
          <el-input
            v-else
            type="textarea"
            placeholder="请输入备注"
            autosize
            :ref="scope.row.planNumber"
            :maxlength="500"
            @blur="toggleRemark(scope.row)"
            v-model="scope.row.remarks"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="查看详情"
            placement="top-start"
          >
            <el-button
              type="primary"
              plain
              circle
              size="mini"
              icon="iconfont icon-chakan"
              @click="showPlanDetail(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-dropdown class="ml10">
            <el-button
              type="primary"
              plain
              icon="el-icon-more"
              circle
              :disabled="[3, 4].includes(scope.row.status)"
              style="padding: 8px"
            ></el-button>
            <el-dropdown-menu slot="dropdown" placement="bottom">
              <!-- <el-dropdown-item>
                <p @click="editTransPlan(scope.row)">编辑</p>
              </el-dropdown-item> -->
              <el-dropdown-item>
                <p @click="showPlanDetail(scope.row)">正式出运</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- <ErpSummary :summayData="summayData" :summaryParams="summaryParams" /> -->
    <Pagination
      v-if="page.total > 0"
      :limit.sync="page.pageLe"
      :page.sync="page.pageNo"
      :total="page.total"
      @pagination="onPagination"
    />
    <!-- 修改出运计划 -->
    <UpdateTransportationPlan
      ref="UpdateTransportationPlan"
      @success="getShipmentPlan"
      v-if="showUpdateTransportationPlan"
    />
  </div>
  <div v-else class="pro-container">
    <router-view />
  </div>
</template>

<script>
  import * as _ from 'dayjs'
  import Pagination from '@/components/Pagination/Index'
  import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'
  import { ShipmentMangeInteractor } from '@/core/interactors/shipment/shipmentManage'
  import { OrderSettingInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import popoverShip from './components/popoverShip.vue'
  import UpdateTransportationPlan from '@/views/order/orderList/components/updateTransportationPlan.vue'
  import { orderNewGetDetailByOrderCode } from '@/api/order'
  const index2Sum = {
    0: 'totalNum', //总件数
    1: 'totalCase', //总箱数
    2: 'totalGross', //总毛重
    3: 'totalVolume', //总体积
    4: 'totalChargedWeight',
    5: 'totalFreight',
  }
  export default {
    components: {
      Pagination,
      popoverShip,
      UpdateTransportationPlan,
    },
    data() {
      return {
        filterForm: {
          planNumber: '',
          piNumber: '',
          businessName: '',
          businessId: '',
          shippingMethod: '',
          shippingDate: [],
          deliveryDate: [],
          destination: '',
          sku: '',
        },
        searchFormConfig: {
          labelPosition: 'right',
          defaultExpand: false, //默认展开
          formItemList: [
            {
              type: 'text',
              name: 'planNumber',
              prop: 'planNumber',
              label: '计划单号',
              placeholder: '请输入',
              maxlength: 30,
            },
            {
              type: 'text',
              name: 'piNumber',
              prop: 'piNumber',
              label: '订单编号',
              placeholder: '请输入',
              maxlength: 30,
            },
            {
              type: 'select',
              name: 'shippingMethod',
              prop: 'shippingMethod',
              label: '运输方式',
              placeholder: '请选择',
              maxlength: 30,
              optList: [],
              multiple: true,
            },
            {
              type: 'daterange',
              name: 'shippingDate',
              prop: 'shippingDate',
              label: '计划装运日期',
              placeholder: '请选择',
              maxlength: 30,
              labelWidth:'100px'
            },
            {
              type: 'daterange',
              name: 'deliveryDate',
              prop: 'deliveryDate',
              label: '客户交期',
              placeholder: '请选择',
              maxlength: 30,
            },
            {
              type: 'text',
              name: 'destination',
              prop: 'destination',
              label: '目的地',
              placeholder: '请输入',
              maxlength: 30,
            },
            {
              type: 'userchoose',
              name: 'businessName',
              prop: 'businessName',
              label: '客户代表',
              placeholder: '请选择',
              maxlength: 30,
              chooseUser: this.chooseUser,
            },
            {
              type: 'text',
              name: 'sku',
              prop: 'sku',
              label: '出运产品',
              placeholder: '请输入',
            },
          ],
          operate: [
            {
              type: 'primary',
              name: '搜索',
              handleClick: this.onButtonSearch,
            },
            {
              type: 'plain',
              name: '重置',
              handleClick: this.onReset,
            },
          ],
        },
        summaryParams: {
          type: 1, // 1当前页 2 勾选项 3 筛选项
        },
        summayData: [
          {
            //合计信息
            label: '总件数',
            count: 0,
          },
          {
            //合计信息
            label: '总箱数',
            count: 0,
          },
          {
            //合计信息
            label: '总毛重',
            count: 0,
          },
          {
            //合计信息
            label: '总体积',
            count: 0,
          },
        ],
        drawer: false,
        form: { condition: '' },
        tableData: [],
        products: [],
        page: {
          pageLe: 10,
          pageNo: 1,
          total: 10000,
        },
        tableHeight: 600,
        selection: [],
        showUpdateTransportationPlan: false,
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
      tenantId() {
        return this.userInfo.tenantId
      },
    },
    filters: {
      timeFilter(val) {
        if (!val) return '--'
        return _(val).format('YYYY-MM-DD')
      },
    },
    mounted() {
      this.getTableHeight()

      this.$Bus.$on('form-trigger', () => {
        this.getTableHeight()
      })
    },
    created() {
      if (
        this.$route.query &&
        this.$route.query.skus &&
        this.$route.query.salesOrderNumbers
      ) {
        this.filterForm.piNumber = this.$route.query.salesOrderNumbers
        this.filterForm.sku = this.$route.query.skus
        this.searchFormConfig.defaultExpand = true
      }
      this.getList()
      // 获取货运方式
      ShipmentMangeInteractor.transpotwayList().then((res) => {
        if (res && res.code === '000000') {
          this.searchFormConfig.formItemList[2].optList = res.data.map((i) => {
            i.id = i.id + ''
            i.label = i.transportWayCn
            i.value = i.transportWayCn
            return i
          })
        }
      })
    },
    watch: {
      'filterForm.businessName': function (val) {
        if (!val) {
          // 清空名称时，清空id
          this.filterForm.businessId = ''
        }
      }
    },
    methods: {
      // 列表复选框勾选时间
      handleSelectionChange(val) {
        this.selection = val
        if (this.summaryParams.type != 2) {
          return
        }
      },
      // 获取当前页或者勾选项的shippingOrderIds,以逗号拼接
      getShippingPlanOrderIds() {
        const { type } = this.summaryParams
        if (type === 3) return ''
        return this[type === 1 ? 'tableData' : 'selection']
          .map((item) => item.shippingPlanOrderId)
          .join(',')
      },
      // 获取查询请求参数
      _getSearchRequestParams() {
        const requestData = this._handleRequetData(),
          { pageLe, pageNo } = this.page

        return {
          ...requestData,
          pageLe,
          pageNo,
        }
      },
      // 计算table高度
      getTableHeight() {
        setTimeout(() => {
          let wrapper = document.querySelector('.custom-container')
          if (!wrapper) {
            return
          }
          const wrapperH = document
            .querySelector('.custom-container')
            .getBoundingClientRect().height

          const otherH =
            document.querySelector('.search-form')?.getBoundingClientRect()
              ?.height +
            35 +
            72 

          this.tableHeight = wrapperH - otherH
        }, 200)
      },
      // 客户代表选择
      chooseUser() {
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId: '',
            canSelectZeroUser: false,
            singleSelect: true,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, nameEn } = users[0]
              this.filterForm.businessName = name
              this.filterForm.businessId = id
            }
          })
      },
      arrangeShipping(shippingPlanOrderId) {
        // 先获取订单详情
        ShippingPlanInteractor.getShipmentPlanDetail({
          shippingPlanOrderId: shippingPlanOrderId,
          shippingPlanOrderProductIds: '',
        })
          .then((res) => {
            return res.data.productDetails
          })
          .then((ele) => {
            ShippingPlanInteractor.arrangeShipQuery({
              shippingPlanOrderId: shippingPlanOrderId,
              arrangeProductList: ele,
            }).then((res) => {
              if (res && res.code === '000000') {
                if (
                  res.data.productArranges &&
                  res.data.productArranges.length > 0
                ) {
                  this.$router.push({
                    path: '/order/new-shipment-sheet',
                    query: {
                      shippingPlanOrderId,
                    },
                  })
                } else {
                  this.$confirm('产品无库存，无法安排出运。', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                  })
                    .then(() => {})
                    .catch(() => {})
                }
              }
            })
          })
      },
      toggleRemark(row) {
        let self = this
        if (!row.remarkEdit) {
          setTimeout(() => {
            self.$refs[row.planNumber].focus()
          }, 500)
        }
        if (!('remarkEdit' in row)) {
          this.$set(row, 'remarkEdit', true)
        } else {
          row.remarkEdit = !row.remarkEdit
        }

        if (!row.remarkEdit) {
          //失去焦点
          const { remarks, shippingPlanOrderId, oldRemark } = row
          if (oldRemark === remarks) {
            return
          } else {
            row.oldRemark = remarks
            ShippingPlanInteractor.updateRemark({
              remarks,
              shippingPlanOrderId,
            }).then((res) => {
              if (res && res.code === '000000') {
                this.$message.success('编辑成功')
              }
            })
          }
        }
      },
      remarkBlur(row) {
        // 提交请求
        this.remarkEdit = false
      },
      // 产品详情
      showProDetail(proId) {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId,
            editProduct: 1,
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },

      // 出运计划详情
      async showPlanDetail({ shippingPlanOrderId, piNumber }) {
        this.$router.push({
          path: '/order/shipmentManage/shipment-plan-detail',
          query: { shippingPlanOrderId, orderCode: piNumber },
        })
      },
      async showOrderDetail({ piNumber }) {
        return navigateTo(this, {
          orderNumber: piNumber,
          pageType: PAGE_TYPES.salesOrderDetail,
        })
      },
      // 获取计划单差产品详情
      getPros({ shippingPlanOrderId }) {
        ShippingPlanInteractor.getShipmentPlanPro({
          shippingPlanOrderId,
        }).then((res) => {
          if (res && res.code === '000000') {
            this.products = res.data || []
          } else {
            this.products = []
          }
        })
      },
      // 关闭产品详情
      onProHide() {
        this.products = []
      },
      onPagination({ page, limit }) {
        this.page.pageNo = page
        this.page.pageLe = limit

        this.getList()
      },
      onButtonSearch() {
        this.page.pageNo = 1
        this.onSearch()
      },

      onSearch() {
        this.page.pageLe = 10
        this.page.pageNo = 1
        this.getList()
      },

      // 处理请求参数
      _handleRequetData() {
        const dateFilds = ['shippingDate', 'deliveryDate']
        const noDateFields = [
          'planNumber', //计划单号
          'piNumber', //订单编号
          'destination', // 目的地
          'businessId', //客户代表
          'sku', //出运产品
        ]
        const filterForm = this.filterForm
        const reqData = {}
        dateFilds.forEach((field) => {
          if (filterForm[field] && filterForm[field].length > 0) {
            reqData[field + 'Start'] = filterForm[field][0]
            reqData[field + 'End'] = filterForm[field][1]
          } else {
            reqData[field + 'Start'] = ''
            reqData[field + 'End'] = ''
          }
        })

        noDateFields.forEach((field) => {
          reqData[field] = filterForm[field] && filterForm[field].trim()
        })
        reqData.shippingMethod = Array.isArray(filterForm.shippingMethod)
          ? filterForm.shippingMethod.join(',')
          : ''
        return reqData
      },

      getList() {
        let { pageLe, pageNo } = this.page,
          tenantId = this.tenantId

        const requestParams = {
          pageLe,
          pageNo,
          tenantId,
          ...this._handleRequetData(),
        }

        ShippingPlanInteractor.getShipmentPlan(requestParams).then((res) => {
          if (res.code === '000000') {
            this.page.total = res.data.total
            this.tableData = res.data.list || []
          }
        })
      },

      onReset() {
        this.filterForm = this.$options.data().filterForm
        this.onSearch()
      },

      // 修改出运计划
      editTransPlan(item) {
        const { piNumber: orderCode } = item
        // 判断是否可以编辑
        orderNewGetDetailByOrderCode({ orderCode }).then((res) => {
          if(res?.code === '000000') {
              const isSettlementStatus =
              res.data.settlementStatus > 1 || res.data.settlementStatus == 4
                ? false
                : true
              if (!isSettlementStatus) {
                return this.$message.warning(
                  `不支持编辑，请查看销售订单${orderCode}的结算状态`
                )
              }
              this._updateTransportationPlan(item)
          }

        })
      },
      // 回调
      getShipmentPlan() {
        this.showUpdateTransportationPlan = false
        this.getList()
      },
      // 显示编辑出运计划
      _updateTransportationPlan(item) {
        this.showUpdateTransportationPlan = true
        setTimeout(() => {
          this.$refs.UpdateTransportationPlan.show(item)
        }, 300)
      },
    },
  }
</script>

<style scoped lang="scss">
  .custom-container {
    overflow-y: hidden !important;
  }

  .page-header {
    height: 33px;
  }

  ::v-deep {
    .page-header {
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #f5f7fa;
    margin: 9px 0 15px 0;
  }

  i {
    font-size: 18px;
    margin: 0 6px;
  }

  .save-btn {
    border-top: 1px solid #ececec;
    padding-top: 10px;
    text-align: center;
  }

  .error-color {
    color: #f56c6c;
  }

  .success-color {
    color: #67c23a;
  }

  .warning-color {
    color: #e6a23c;
  }

  .pro-container {
    overflow-y: hidden !important;
  }
</style>

<style>
  .el-tooltip__popper {
    max-width: 400px;
  }
</style>
