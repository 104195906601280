<template>
  <div class="product-img-box">
    <el-image
      :src="product.productImg && product.productImg.split(',')[0]"
      :preview-src-list="product.productImg.split(',')"
      alt=""
      style="height: 80px; width: 80px"
      :key="product.productImg"
    ></el-image>
    <span class="fileAnnex-span" v-if="product.productImg && product.productImg.split(',').length > 1">
      {{ product.productImg.split(',').length }}
    </span>

    <el-tag
      size="mini"
      class="el-tag-other"
      v-if="product.standardCustomized === 1"
    >
      标准品
    </el-tag>
    <span v-if="showMain" class="main-child-tag" :class="{ 'is-main': isMain }">
      {{ textTag }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'ProductImg',
    props: {
      product: {
        // 产品信息
        type: Object,
        required: true,
      },
      isMain: {
        // 是否是主产品
        type: Boolean,
        default: true,
      },
      showMain: {
        // 是否显示主子产品标签
        type: Boolean,
        default: true,
      },
    },

    computed: {
      textTag() {
        return this.isMain ? '主' : '子'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-img-box {
    position: relative;
    height: 80px;
    width: 80px;

  }
  .main-child-tag {
    position: absolute;
    bottom: 0px;
    font-size: 13px;
    right: 0px;
  }
  .el-tag-other {
    position: absolute;
    font-size: 13px;
    top: 0px;
    right: -27px;
  }
  ::v-deep {
    .el-image {
      height: 80px !important;
      width: 80px !important;
    }
  }

  .main-child-tag {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 2.5px;
    background-color: #facd91;
    color: #7b4d12;
    &.is-main {
      background-color: #caf982;
      color: #4b7902;
    }
  }
  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
    position: absolute;
    right: -23px;
    top: 40px;
  }
</style>
